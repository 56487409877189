@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* shadcn */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  /* * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  } */
  * {
    box-sizing: border-box;
    
    font-family: "Inter", sans-serif !important;
  }

  img{
    display: inline !important;
  }
  .svg-inline{
    display: inline !important;
  }
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
     overflow-y: scroll !important;
    font-family: "Inter", sans-serif !important;
  }
}

/* shadcn end */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,400&family=Roboto:wght@400;500&display=swap");


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');
 */


/* * {
  box-sizing: border-box;
  
  font-family: "DM Sans", sans-serif !important;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important;
  
  font-family: "DM Sans", sans-serif !important;
} */
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style-type: none;
}
a {
  text-decoration: none !important;
}
.text-primary {
  color: #de3820;
}
.text-primary-imp {
  color: #de3820 !important;
}

.shadowsm{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/* submit button */
.submit-button {
  background-color: #6622cc;
  width: 100%;
  color: #fff;

  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.submit-button-disable {
  background-color: #b8aec6;
  width: 100%;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.goole-container > :first-child{
    width: 100% !important;
}
/* Hover effect */
/* .submit-button:hover {
  background-color: #aa4839; 
  transform: scale(1.1); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
} */
.table-bg{
 background-color:rgba(240, 240, 240, 1);
}
.table-color{
  /* color: rgba(131, 131, 131, 1); */
  color: #323232;
}
.submit-button-outline {
  background-color: #fff;
  width: 100%;
  color: #6622cc;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;

  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.otp-filds input:focus{
     border-bottom: 2px solid #6622cc !important;
     border-top: none !important;
     border-right: none !important;
     border-left: none !important;
     outline: none !important;
}
.submit-button-google{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important; 
}
/* submit button end */
.dropshadow{
  
  filter: blur(6.710577);
}
.form-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px 12px 5px 12px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  /* transition: transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  position: relative;
  overflow: hidden;
  min-height: 88vh;
  margin-top: 24px;
  position: relative;
}
/* .form-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
} */

.btn-confirm-pay{
  width: 47%;
}
 

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 6px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  transition: box-shadow 0.3s, border-color 0.3s;
}

.loader-spinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, #c1addf 33%, #6622cc 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-spinner::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: white;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
} 

/* customize stepper of property page */
.stepLabelCustomize.Mui-active{
  color: #6622CC !important;
}




/* slect lead quality */


/*  */
.leaflet-container {
  height: 400px;
  width: 100%;
}




/* for loader */
@keyframes colorChange {
  0% {
    fill: rgb(228, 91, 62);
  }
  50% {
    fill: rgb(108, 72, 164);
  }
  100% {
    fill: rgb(228, 91, 62);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

.svgElementColorChange {
  animation: colorChange 5s infinite;
}

.svgElementMoveRight {
  animation: moveRight 2s infinite;
}


.blinkloading-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 34px; /* Adjust height as needed */
}

.blinkloading-dot {
  width: 6px;
  height: 6px;
  background-color: rgba(94, 91, 91, 0.87);
  border-radius: 50%;
  margin: 0 5px; /* Adjust spacing between dots as needed */
  opacity: 0; /* Start with dots invisible */
}


.dot1 {
  animation: blink1 1s infinite; /* Apply the blinking animation to the first dot */
}

.dot2 {
  animation: blink2 1s infinite; /* Apply the blinking animation to the second dot */
}

.dot3 {
  animation: blink3 1s infinite; /* Apply the blinking animation to the third dot */
}

@keyframes blink1 {
  0%, 16.67% {
    opacity: 1; /* Make the dot fully visible for the first part of the animation */
  }
  50%, 83.33% {
    opacity: 0.3; /* Make the dot slightly visible for the second part of the animation */
  }
}

@keyframes blink2 {
  16.67%, 50% {
    opacity: 1; /* Make the dot fully visible for the first part of the animation */
  }
  83.33%, 100% {
    opacity: 0.3; /* Make the dot slightly visible for the second part of the animation */
  }
}

@keyframes blink3 {
  50%, 83.33% {
    opacity: 1; /* Make the dot fully visible for the first part of the animation */
  }
  16.67%, 100% {
    opacity: 0.3; /* Make the dot slightly visible for the second part of the animation */
  }
}


@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }



.swiper {
  width: 100%;
 
}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}


.login-bg-img{
  background-image:url("../src/asets/login-1-background\ \(1\).jpg");
 

  object-fit: cover; 
  background-repeat: no-repeat; 
  background-position: center; 
  width:100%;

}



.leaflet-popup .leaflet-zoom-animated{
     bottom: 20px !important;
}

.clalender-bg .bg-primary{
  background-color: #62C !important;
 
}
.aria-selected\:text-accent-foreground[aria-selected="true"] {
  color: hsl(222.2deg 4.2% 62.52%) !important;
}
.text-decoration{
  text-decoration: underline !important;
}

/* loader for fb button */
.loaderfb {
  width: 30px;
  height: 30px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 


  .loaderfbpage {
    width: 22px;
    height: 22px;
    border: 3px solid #62c;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotationpage 1s linear infinite;
    }
  
    @keyframes rotationpage {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 


    .addbudgetloader {
      width: 22px;
      height: 22px;
      border: white;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotationaddbudget 1s linear infinite;
      }

      @keyframes rotationaddbudget {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        } 
   
 


@media (min-width: 760px) {
  .form-card {
    width: 100%;
    box-shadow: none;
    padding: 8px;
    margin-top: 10px !important;
  }
  .stepLabelCustomize.Mui-active{
    color: #6622CC !important;
  }
  /* .form-card:hover {
    transform: translateY(-5px);
    box-shadow: none;
  } */
  .MuiFormControlLabel-label {
    text-align: justify !important;
  }
  .btn-confirm-pay{
    width: 100%;
  }
  
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #ccc;
  pointer-events: auto;
  background-color: transparent !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  height: 100vh;
}
/* Center the image */
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.primarybg-col{
  background-color: #62C;
}
.MuiDrawer-paperAnchorLeft{
  background-color: white !important;
}


/* loader for fb button */
.loaderfb {
  width: 30px;
  height: 30px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

.margin-top-auth{
   margin-top: 70%;
}
.margin-top-auth2{
  margin-top: 15vh;
}
/* remove datepicker cross button */
/* .delete-button img{
    display: none;
} */
@media (min-width:780px){
  .margin-top-auth{
    margin-top: 6%;
 }
 .reviewpage-planoffer-p{
  font-size: 12px;
  line-height: 22px;
 }
 .stepLabelCustomize.Mui-active{
  color: #6622CC !important;
}
.btn-confirm-pay{
  width: 100%;
}
.margin-top-auth2{
  margin-top: 6vh;
}
 
}


/* landing page */

.section-py{
  padding: 4rem 4px;
  background-color: black;
}
.section-py-threestep{
  padding: 4rem 0px 0px 0px;
}

.h3-text{
  font-size: 42px;
  font-weight: 500;
  font-family: "Instrument Sans", sans-serif;
  color: white;
}



  .text-gradient{
    background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
  }


  .swiper {
    width: 100%;
  
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
   
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width:764px){
    .h3-text{
      font-size: 25px;
      line-height: normal;
    }
  }







  .text-gradient{
    background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
  }


  .swiper {
    width: 100%;
  
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
   
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width:764px){
    .h3-text{
      font-size: 25px;
      line-height: normal;
    }

    /* Custom CSS to change the position of the Intercom widget */
    .intercom-lightweight-app-launcher{
      bottom: 76px !important;
      right: 20px !important;
    }

/* Optionally, you can target the launcher button separately */
  }


  @media (max-width: 1024px) {
    .intercom-lightweight-app-launcher {
    bottom: 72px !important;
    }
     
    /*localhost*/
    .intercom-namespace .intercom-with-namespace-52k34s {
      user-select: none;
      z-index: 2147483000;
      position: fixed;
      bottom: 75px !important;
      right: 20px;
      width: 48px;
      height: 48px;
      transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
      transform-origin: center center;
  }
    }
