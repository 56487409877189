.page-not-found-container{
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 95vh;
}
.page-not-found-card{
  padding: 10px;
}
.oops-text{
    font-size: 100px;
    font-weight: 900;
    text-align: center;
    background: #121FCF;
   background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
    -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;

}
.text404{
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.message404{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.page404button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}


@media (max-width: 760px) {
   

}
