.leapxSupportContainer {
  padding-inline: 10px;
  width: 100%;
}
.leapxSupportContainer .previous {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-block: 20px;
}

.leapxSupportContainer .previous h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  font-family: "DM SANS", sans-serif;
}
.leapxSupportContainer button[type="submit"] {
  background-color: #6622cc;
  color: white;
  width: 100%;
  text-align: center;
  display: block;
  height: 54px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  outline: none;
}
