.firstPage {
  /* border: 1px solid #000; */
  background: #f6f6f6;
}

.accordion-container {
  /* max-width: 350px; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f6f6f6;
}
.accordion1 {
  width: 100%;
  margin: 0 auto;
  /* max-height: 331px; */
}
label.label {
  color: #656565;
  font-family: DM Sans;
  font-size: 14.452px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inputBox {
  width: 316.129px;
  height: 43.355px;
  border-radius: 5.419px;
  /* border: 0.903px solid #d4d4d4; */
  /* background-color: rgba(255, 255, 255, 0.2); */
  color: #000;
  font-family: DM Sans;
  font-size: 10.839px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-inline: 17.94px;
  margin-block: 7.19px 20.45px;
  outline: unset;
}
.inputBox:last-of-type {
  margin-bottom: 10px;
}
.personalColors {
  border: 0.903px solid #d4d4d4;
  background-color: rgba(255, 255, 255, 0.2);
}
/* .MuiAccordionSummary-root {
  min-height: unset;
} */
.css-4uv92p-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: unset !important;
}

.accordion2 {
  width: 100%;
  margin: 0 auto;
}
.BuisnessColors {
  border-radius: 5.419px;
  border: 0.903px solid #ddd;
  background: #fdfdfd;
}
#category {
  display: content;
  width: 100%;
  height: 43.355px;
  color: #141414;
  font-family: DM Sans;
  font-size: 10.839px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-inline: 10px;
  margin-block: 7.19px 20.45px;
  /* display: none; */
}
.categorySelectBtn {
  border-radius: 5.419px;
  border: 0.903px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43.355px;
  background: #fdfdfd;
  padding-inline: 17px;
  /* text */
  color: #141414;
  font-family: DM Sans;
  font-size: 10.839px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block: 7.19px 20.45px;
}
.customFileSelect {
  display: block;
}
.selectedFile {
  margin-bottom: 5px;
}
.submitBtn {
  display: flex;
  justify-content: right;
}
.saveBtn {
  padding: 8px 16px;
  outline: none;
  border: none;
  border-radius: 5px;
  background: var(--p-voilet, #62c);
  display: flex;
  gap: 5px;
  align-items: center;

  /* text */
  color: #fff;

  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.linkedAccount {
  width: 100%;
  margin: 0 auto;
  margin-block: 15px;
  background-color: #6622cc0d;
  padding: 20px 16px;
  font-weight: 700;
  font-family: DM Sans;
  border-radius: 20px;
}
.pages {
  display: flex;
  gap: 5px;
}
.page {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #6622cc;
  border-radius: 500px;
  color: white;
  font-size: 12px;
  font-weight: 400;
}




