
/* add pages button */

.add-facebook-pages-new{
    font-size: 16px;
    color: white;
    padding: 10px 28px;
    border-radius: 6px;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #1877f2;
}


.complete-profile-card-container{
color: white;
}
.complete-profile-card{
    width: 100%;
}

.profile-circle-percentage{
   width: 100px;
   height: 100px;
   border-radius: 50%;
}

.profile-circle-percentage-inner{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color:rgba(226, 214, 243, 1);
}
.firstName-text{
    color: rgba(102, 34, 204, 1);
    font-size:44px;
    font-weight: 500;
    line-height:52px ;

}
.completed-percentage-text{
    color: rgba(51, 65, 85, 1);
}
.ad-cp-p{
    font-size: 14px;
    font-weight: 500;
    /* line-height: 20px; */
    color: rgba(51, 65, 85, 1);
}
.ad-cp-name{
    font-size: 18px;
    font-weight: 500;
    /* line-height: 23.44; */
    color: rgba(32, 32, 32, 1);
}
.ad-freemembership-p{
    font-size: 14px;
    font-weight: 500;
    /* line-height: 18.23px; */
    color: rgba(80, 80, 80, 1);
}
.complete-profile-text{
    font-size: 24px;
    /* line-height:36px ; */
    color:rgba(15, 23, 42, 1) ;
    font-weight:600;

}

.complte-profile-circular-box{
    width: 100px;
    height: 100px;
}
.complete-ptrofile-percentage-card{
    width: 215px;
}
.percentage-width{
    width: 40px;

}
.ad-main-h{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}
/*  */

.percentage-card-width{
    width: 100%;
}

@media(max-width:768px){
   .add-facebook-pages-new{
    font-size: 12px;
    padding: 6px 9px;
    font-weight: 500;

   } 

   /* complete profile */
   .profile-circle-percentage-inner{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color:rgba(226, 214, 243, 1);
}
profile-circle-percentage-inner{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color:rgba(226, 214, 243, 1);
}
.complte-profile-circular-box{
    width: 64px;
    height: 64px;
}
.complete-profile-text{
    font-size: 16px;

}
.ad-cp-p{
    font-size: 10px;
   
}
.complete-pro-icon{
    width: 12px;
    height: 12px;
}
.complete-ptrofile-percentage-card{
    width: 100%;
}
.percentage-width{
    width: 24px;
}
.ad-freemembership-p{
    font-size: 10px;
    font-weight: 500;
    /* line-height: 18.23px; */
    /* color: rgba(80, 80, 80, 1); */
}
.ad-cp-name{
    font-size: 14px;
    font-weight: 500;
    /* line-height: 23.44; */
    color: rgba(32, 32, 32, 1);
}
.ad-main-h{
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
}
.percentage-card-width{
    width:auto;
}
}
