/* .overview-card{
    filter: blur(box-shadow: 0px 4px 14.199999809265137px 0px #0000001A;);
}
.dropshadow{
    filter: blur(box-shadow: 0px 4px 14.199999809265137px 0px #0000001A;);
} */

.home-overview4-card p{
      font-size: 12px;
      font-weight: 500;
}
.home-overview4-card p img{
      height: 12px;
      width: 12px;
}

.home-overview1-card .overview-card{
width:100%;
 height:116px;
 border-radius: 6px;
}

.home-overview2-card .overview-card{
    width:100%;
 height:116px;
 border-radius: 6px;
}

.home-overview3-card .overview-card{
 width:100%;
 height:116px;
 border-radius: 6px;
}

.overview-content .overview-heading{
   font-size: 13.5px;
   font-weight: 500;
}
.overview-content .overview-number{
    font-size: 24px;
    font-weight: 700;
 }
 
 .thired-overviewcard .overview-card{
  width: 100%;
  height: 116px;
  background-color:#FFFDF4 ;
  border-radius: 6px;
}
.home-overview4-card p{
  font-size: 13.5px;
  font-weight: 500;
}
.home-overview4-card p img{
  height: 12px;
  width: 12px;
}

/* impression card */
.HomeIpressionViewSpentCard{
  width:100%;
  height:74px;
}


.HomeIpressionViewSpentCard div .imp-h{
 font-size: 13.5px;
 font-weight: 500;
 color: #C4C4C4;
}
.HomeIpressionViewSpentCard div .imp-num{
color: black;
font-size: 24px;
font-weight: 700;
} 


@media (min-width:992px) and (max-width: 1200px) {
  .home-overview4-card p{
    font-size: 16px;
    font-weight: 500;
}
.home-overview4-card p img{
    height: 12px;
    width: 12px;
}

.home-overview1-card .overview-card{
width:100%;
height:90px;
border-radius: 4px;
}

.home-overview2-card .overview-card{
width:100%;
height:90px;
border-radius: 4px;
}

.home-overview3-card .overview-card{
width:100%;
height:90px;
border-radius: 4px;
}
.home-overview1-card .overview-card div img{
width: 40px;
height: 40px;
}
.home-overview2-card .overview-card div img{
width: 40px;
height: 40px;
}
.home-overview3-card .overview-card div img{
width: 40px;
height: 40px;
}


.overview-content .overview-heading{
 font-size: 20px;
 font-weight: 500;
}
.overview-content .overview-number{
  font-size: 22px;
  font-weight: 700;
}

.thired-overviewcard .overview-card{
  width: 100%;
  height: 90px;
  background-color:#FFFDF4 ;
  border-radius: 6px;
}


/* impression card */
.HomeIpressionViewSpentCard{
    width:100%;
    height:74px;
}


.HomeIpressionViewSpentCard div .imp-h{
   font-size: 11px;
   font-weight: 500;
   color: #C4C4C4;
}
.HomeIpressionViewSpentCard div .imp-num{
  color: black;
  font-size: 15px;
  font-weight: 700;
} 
   
}





/* wallet card */

@media (min-width:768px) and (max-width: 992px) {
    .home-overview4-card p{
        font-size: 11px;
        font-weight: 500;
  }
  .home-overview4-card p img{
        height: 12px;
        width: 12px;
  }
  
  .home-overview1-card .overview-card{
  width:100%;
   height:90px;
   border-radius: 4px;
  }
  
  .home-overview2-card .overview-card{
    width:100%;
    height:90px;
    border-radius: 4px;
  }
  
  .home-overview3-card .overview-card{
    width:100%;
    height:90px;
    border-radius: 4px;
  }
  .home-overview1-card .overview-card div img{
    width: 40px;
    height: 40px;
  }
  .home-overview2-card .overview-card div img{
    width: 40px;
    height: 40px;
  }
  .home-overview3-card .overview-card div img{
    width: 40px;
    height: 40px;
  }
  
  .overview-content .overview-heading{
     font-size: 11px;
     font-weight: 500;
  }
  .overview-content .overview-number{
      font-size: 18px;
      font-weight: 700;
   }
   .thired-overviewcard .overview-card{
    width: 100%;
    height: 90px;
    background-color:#FFFDF4 ;
    border-radius: 6px;
  }

  /* impression card */
.HomeIpressionViewSpentCard{
  /* max-width:200px;
  min-width: 120px; */
  height:74px;
}
.HomeIpressionViewSpentCard div img{

}

.HomeIpressionViewSpentCard div .imp-h{
 font-size: 11px;
 font-weight: 500;
 color: #C4C4C4;
}
.HomeIpressionViewSpentCard div .imp-num{
color: black;
font-size: 18px;
font-weight: 700;
} 

 }

 @media (min-width: 992px) and (max-width: 1199px){
  .home-overview4-card p{
    font-size: 10px;
    font-weight: 500;
}
.home-overview4-card p img{
    height: 8px;
    width: 8px;
}

.home-overview1-card .overview-card{
width:100%;
height:90px;
border-radius: 4px;
}

.home-overview2-card .overview-card{
width:100%;
height:90px;
border-radius: 4px;
}

.home-overview3-card .overview-card{
width:100%;
height:90px;
border-radius: 4px;
}
.home-overview1-card .overview-card div img{
width: 32px;
height: 32px;
}
.home-overview2-card .overview-card div img{
width: 32px;
height: 32px;
}
.home-overview3-card .overview-card div img{
width: 32px;
height: 32px;
}

.overview-content .overview-heading{
 font-size: 11px;
 font-weight: 500;
}
.overview-content .overview-number{
  font-size: 15px;
  font-weight: 700;
}
 }


 /* web wallet card new */

  /* X-Small devices (portrait phones, less than 576px) */
 @media (max-width: 575.98px) { 
       
  }
 
  /* Small devices (landscape phones, less than 768px) */
 @media (min-width:575.98px) and (max-width: 767.98px) { 
  .webWalletCard{
    background-image: url("../../asets/welcomeCard.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    border-radius: 18px;
    max-width: 100%;
    overflow: hidden;
   }
   .active-wallet-text{
    font-size: 10px;
    font-weight: 500;
   }
 
   .inner-webWalletCard{
        
   }
   .wallet-blance-content{

   }
   .circle-spen-container{
     width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
     
   }
   .sped-tilldate-deploy-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center
   }
   .spendtilldatetext{
    font-size: 10px;
    font-weight: 400;
    color: #ffffff8e;
   }
   .main-circular-container{
    width: 35%;
   }
   .circlwallet{
    /* width: 30%; */
   }
   .spend-h{
    font-size: 10px;
    /* color: #ffffff8e; */
   }
   .spend-h span{
    color: white;
   }
   .deploy-h{
    font-size: 10px;
    color: #ffffff8e;
   }
   .deploy-h{
    color: white;
   }
   .sped-tilldate-deploy-container div h6{
    font-size: 14px;
    font-weight: 400;
   }
   .spend-deploy span{
    color:#ffffff8e;
   }
  }
 
  /* Medium devices (tablets, less than 992px) */
 @media (min-width:767.98px) and (max-width: 991.98px) { 
  .webWalletCard{
    background-image: url("../../asets/welcomeCard.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    border-radius: 18px;
    max-width: 100%;
    overflow: hidden;
   }
   .active-wallet-text{
    font-size: 10px;
    font-weight: 500;
   }
 
   .inner-webWalletCard{
        
   }
   .wallet-blance-content{

   }
   .circle-spen-container{
     width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
     
   }
   .sped-tilldate-deploy-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center
   }
   .spendtilldatetext{
    font-size: 9px;
    font-weight: 400;
    color: #ffffff8e;
   }
   .main-circular-container{
    width: 35%;
   }
   .circlwallet{
    /* width: 30%; */
   }
   .spend-h{
    font-size: 9px;
    /* color: #ffffff8e; */
   }
   .spend-h span{
    color: white;
   }
   .deploy-h{
    font-size: 9px;
    color: #ffffff8e;
   }
   .deploy-h{
    color: white;
   }
   .sped-tilldate-deploy-container div h6{
    font-size: 10px;
    font-weight: 400;
   }
   .spend-deploy span{
    color:#ffffff8e;
   }
  }
 
  /* Large devices (desktops, less than 1200px) */
 @media (min-width: 991.98px) and (max-width: 1199.98px) { 
  .webWalletCard{
    background-image: url("../../asets/welcomeCard.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    border-radius: 18px;
    max-width: 100%;
    overflow: hidden;
   }
   .active-wallet-text{
    font-size: 12px;
    font-weight: 500;
   }
 
   .inner-webWalletCard{
        
   }
   .wallet-blance-content{

   }
   .circle-spen-container{
     width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
     
   }
   .sped-tilldate-deploy-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center
   }
   .spendtilldatetext{
    font-size: 10px;
    font-weight: 400;
    color: #ffffff8e;
   }
   .main-circular-container{
    width: 35%;
   }
   .circlwallet{
    /* width: 30%; */
   }
   .spend-h{
    font-size: 10px;
    /* color: #ffffff8e; */
   }
   .spend-h span{
    color: white;
   }
   .deploy-h{
    font-size: 10px;
    color: #ffffff8e;
   }
   .deploy-h{
    color: white;
   }
   .sped-tilldate-deploy-container div h6{
    font-size: 14px;
    font-weight: 400;
   }
   .spend-deploy span{
    color:#ffffff8e;
   }
  }
 
  /* X-Large devices (large desktops, less than 1400px) */
 @media (min-width:1199.98px) and (max-width: 1399.98px) { 
  .webWalletCard{
    background-image: url("../../asets/welcomeCard.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    border-radius: 18px;
    max-width: 100%;
    overflow: hidden;
   }
   .active-wallet-text{
    font-size: 16px;
    font-weight: 500;
   }
 
   .inner-webWalletCard{
        
   }
   .wallet-blance-content{

   }
   .circle-spen-container{
     width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
     
   }
   .sped-tilldate-deploy-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items:center
   }
   .spendtilldatetext{
    font-size: 13px;
    font-weight: 400;
    color: #ffffff8e;
   }
   .main-circular-container{
    width: 35%;
   }
   .circlwallet{
    /* width: 30%; */
   }
   .spend-h{
    font-size: 13px;
    /* color: #ffffff8e; */
   }
   .spend-h span{
    color: white;
   }
   .deploy-h{
    font-size: 13px;
    color: #ffffff8e;
   }
   .deploy-h{
    color: white;
   }
   .sped-tilldate-deploy-container div h6{
    font-size: 13px;
    font-weight: 400;
   }
   .spend-deploy span{
    color:#ffffff8e;
   }
  }

  @media (min-width: 1399.98px) {
  /* xxxl */
  .webWalletCard{
    background-image: url("../../asets/welcomeCard.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    color: white;
    border-radius: 18px;
    max-width: 100%;
    overflow: hidden;
   }
   .active-wallet-text{
    font-size: 20px;
    font-weight: 500;
   }
 
   .inner-webWalletCard{
        
   }
   .wallet-blance-content{

   }
   .circle-spen-container{
     width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
     
   }
   .sped-tilldate-deploy-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items:center
   }
   .spendtilldatetext{
    font-size: 14px;
    font-weight: 400;
    color: #ffffff8e;
   }
   .main-circular-container{
    width: 30%;
   }
   .circlwallet{
    /* width: 30%; */
   }
   .spend-h{
    font-size: 14px;
    /* color: #ffffff8e; */
   }
   .spend-h span{
    color: white;
   }
   .deploy-h{
    font-size: 14px;
    color: #ffffff8e;
   }
   .deploy-h{
    color: white;
   }
   .sped-tilldate-deploy-container div h6{
    font-size: 14px;
    font-weight: 400;
   }
   .spend-deploy span{
    color:#ffffff8e;
   }
  }