/* google button  */
.login-container {
  text-align: center;
}

.google-login-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  margin-top: 30px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.google-login-button:hover {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.google-icon {
  margin-right: 10px;
}

/* Replace this with the actual Google icon image */
.google-icon img {
  width: 20px;
  height: 20px;
}

.google-heading {
  font-size: 20px;
  font-weight: 700;
  color: #6622cc;
}
