.loadingScreen {
 

}
.loadingScreen .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  height: 20px !important;
  /* color: #00c308 !important; */
}
.loadingScreen .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  /* color: #00c308; */
}
.loadingScreen .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #7b7b7b !important;
}
.loadingScreen .css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: #7b7b7b !important;
}
.loadingScreen .stepper {
  /* max-width: 390px;
  margin: 0 auto; */
  position: absolute;
  top: 50%;
  transform: translate(-20%, -50%);
  left: 20%;
}
.loadingScreen .stepper .MuiStepIcon-root {
  height: 20px;
  width: 20px;
  overflow: hidden;
}
.loadingScreen .css-8t49rw-MuiStepConnector-line {
  min-height: 20px;
}
