.paymentHistoryPage .previous {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-block: 20px 11px;
}

.paymentHistoryPage .previous h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  font-family: "DM SANS", sans-serif;
}
.paymentHistoryPage .filter-sort {
  height: 40px;
  width: 40px;
  background-color: rgba(230, 230, 230, 0.5);
  border-radius: 8px;
}
.paymentHistoryPage .searchBox {
  height: 40px;
  min-width: 25ch;
  width: 40px;
  width: 100%;
  border-radius: 8px;
  background: rgba(230, 230, 230, 0.5);
  padding-inline: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.paymentHistoryPage .searchBox input {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: #949494;
}
.p-datatable .p-datatable-thead > tr > th {
  color: white;
  background-color: #62c;
}
.p-datatable {
  margin-top: -20px;
}

.prime-card .p-card-body {
  padding: 1.25rem;
  padding-top: 0.1rem;
}


.wallet-card-container{
  min-width: 400px;
}

.wallet3 {
 
  border-radius: 20px;
  /* background-color: rgb(0, 0, 0, 0.6); */
  background-image: url("../../asets/welcomeCard.svg");
  margin: 0px 20px;
  /* margin-top: -25%; */
  /* margin-top: -120px; */
  margin-top: -74px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-size: cover;

  align-items: center;
}


/* new css for table */
