.draftCards {
  padding: 12px 17px;
  /* border: 1px solid black; */
  border-radius: 16.617px;
  background: #f7f4f3;
}
.draftCards .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.draftCards .header .header-left {
  display: flex;
  gap: 8.31;
  align-items: center;
}
.draftCards .header .header-left img {
  margin: 0;
  /* height: 33px;
  width: 33px; */
}
.draftCards .header .header-left span {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 16.617px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.draftCards .header > span {
  color: #000;

  font-family: "DM Sans", sans-serif;
  font-size: 12.463px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.detailGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-inline: 5px;
}
.detailGrid p {
  display: flex;
  margin-top: 12px;
  gap: 5px;
  align-items: baseline;

  /* text */
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 27;
}
