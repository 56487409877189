.profile-manu{
 width: 85%;
}
.profile-manu li{
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 3px;

}
.profile-manu li a{
    color: white; 
    /* border: 1px solid red; */
}