/* media query */
@media screen and (max-width: 576px) {
    /* hero content */
    .text-grd1 {
        
        background: #dc5300;
        background: linear-gradient(to right, #dc5300 18%, #FFFFFF 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
.hero-container-bg{
    min-height: 60vh;
    background: #36126D;
    background: radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 26%);
    background: -webkit-radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 26%);
    background: -moz-radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 26%);
}

.partnerlogo{
    max-width: 100%;
    height: auto;
}
.hero-container-px{
    padding-top: 5rem;
}

.hero-dotted-bgimg{
    top: 30px;
    opacity: 0.3;
    width: 94%;
   
 }


.magic-of-ai-btn1{
    width: 82%;
    height: 32px;
    border-radius: 999px;
    background-color: transparent;  
    color: white;
    font-size: 13.5px;
    font-weight: 400;
   
    line-height: 20px;
    outline: none;
}
.hero-content-container{
    margin-top: 70px;
}

.try-for-free-btn2 {
    width: 80%;
    height: 40px;
    font-family: "Instrument Sans", sans-serif;
    border-radius: 999px;
    background: linear-gradient(207deg, rgb(47, 41, 43) -70.344%, rgb(4, 1, 2) 100%);
    color: white;
    text-shadow: 0 1px 0 #000;
    --border-angle: 0turn;
    --main-bg: conic-gradient( from var(--border-angle), #213, #112 5%, #112 60%, #213 95% );
    border: solid 1px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #fff, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
    font-size: 14px;
}


.try-for-free-btn {
    width: 135px;
    height: 35px;
    font-size: 11.5px;

}

.getdemo-btn-outer{
    width: 80%;
}


.get-demo-btn{
    width: 100%;
    height: 40px;
    border-radius: 999px;
    background-color: transparent;
    border: 1px solid rgb(38, 33, 33);
    color: white;
    font-size: 11.5px;
    font-weight: 400;
    line-height: 18px;
    outline: none;
}
.socialicon-inner-container{
    width: 180px;
}
.hero-text-h{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    color: white;
   
}
.hero-text-p{
    font-size: 14px;
    font-weight: 400;
    max-width: 522px;
    line-height: 19px;
    margin: 4px 0px;
   

}

.brand-logo-main-container{
   
    padding: 1rem 10px;
    
    /* background-color: rgba(9, 9, 9, 1); */
}
.importantoutcome-p{
    top: -38px;
    width: 99%;
}
.brand-container{
    width: 96%;
    margin: 0 auto;
}
.logo-container{
    width: 60px;
    height: 60px;
    padding: 10px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.tryforfree-btn{
    width:80%;
    height: 40px;
    border-radius: 999px;
    position: relative;
    background-image: linear-gradient(to right, #2F292B, #040102);
    border: none;
    color: white;
    font-size: 11.5px;
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;
    outline: none;
}


/* border animation */
.tryforfree-btn span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
    border-radius: 999px;
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, #171618, rgb(240, 122, 76));
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.tryforfree-btn span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 1px;
	/* background: linear-gradient(to bottom, #171618, #3bff3b);
	animation: animate2 2s linear infinite;
	animation-delay: 1s; */
}
/* @keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
} */

.tryforfree-btn span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to left, #171618, rgb(240, 122, 76));
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.tryforfree-btn span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1px;
	/* background: linear-gradient(to top, #171618, #3bff3b);
	animation: animate4 2s linear infinite;
	animation-delay: 1s; */
}

/* @keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
} */

/*border animation tryfor free end */

/* imp and outcome */

.importantoutcome-h{
    font-size: 28px;
    font-weight: 500;
    line-height:normal;
    color: white;

}
.importantoutcome-p{
    font-size: 14px;
    font-weight: 400;
   
    line-height: 22px;
    color: rgba(255, 255, 255, 0.55);
}
.io-innercard1-h{
    font-size: 37px;
    font-weight: 600;
    line-height: 32px;

    color: rgba(255, 255, 255, 1);
    background: #121FCF;
    background: #F07A4C;
    background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}


.io-innercard1-p{
    font-size: 16px;
    font-weight: 400;
    line-height:19px ;
    color:white;
}

.importantoutcome-container{
    padding: 2rem 0px;
    /* background: radial-gradient(circle at 0% 51%, rgba(255, 92, 0, 1) 0%, rgba(16, 30, 107, 1) 10%, rgba(0, 0, 0, 1) 26%); */
   /* background:radial-gradient(circle at 10% 48%, rgba(255, 92, 0, 1) -19%, #242c52 14%, rgba(0, 0, 0, 1) 33%) */

 
}

.iao-orangebg {
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 999px;
    top: 93.05px;
    filter: blur(46px);
    background-color: rgba(240, 122, 76, 0.6);
    left: 0;
    z-index: 2;
}



.io-innercard1, .io-innercard2{
    width: 46%;
    height: 118px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid rgb(85, 54, 54);
}
.io-innercard3{
    width: 96%;
    height: 118px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid rgb(85, 54, 54);
   
}


/* preview video */
.preview-video-container{
  padding: 2rem 0px 1rem 0px;
    background: radial-gradient(circle at 50% -6%, rgba(102, 34, 204, 0.7) -25%, rgba(0, 0, 0, 1) 59%);
    color: white;
    
 
 }

 .prev-h{
    font-size: 26px;
   text-align: center;
    line-height:44px ;
}
.prev-p{
    font-size: 14px;
    font-weight: 400;
    line-height:24px ; 
    text-align: center;
}



 .moving-start{
     position: absolute;
     
 }
 
 .preview-img-container{
    overflow: hidden;
 
 }
 .prev-h {
    font-size: 25px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    color: white;
}
.prev-p {
    font-size: 14px;
}

/* launch your campaign in tree steps */
.launch-camp-container{
    padding: 2rem 0px;
}

.launch-camp-h{
    font-size: 25px;
    font-weight: 500;
    line-height: normal;
}

.lcs1-left1{
    height: 230px;
}
.lcs1-card-test1{
    font-size: 14px;
    line-height: normal;
}
.lcs1-card-test2{
    font-size: 18px;
    line-height: normal;
}
.lcs1-card-test3{
    font-size: 14px;
    line-height: normal;
}
.lcs1-card-content {
    max-height: 170px;
    position: relative;
    min-height: 100px;
}


.lcs-stick1{
    position: absolute;
    left: -27px;
    bottom: 23px;
    height: 93px;
    
}

.gradient1 {
    background: radial-gradient(circle at 48% 51%, rgba(16, 16, 16, 1) 0%, rgba(102, 34, 204, 1) 6%, rgba(0, 0, 0, 1) 62%);
}
.gradient2{
    background: radial-gradient(circle at 48% 51%, rgba(16, 16, 16, 1) 0%, rgba(255, 92, 0, 0.44) 6%, rgba(0, 0, 0, 1) 62%);
    /* background:radial-gradient(circle at 50% 61%, rgba(16, 16, 16, 1) 0%, rgba(255, 92, 0, 0.44) 0%, hsla(0, 100%, 100%, 0.07) 56%) */
 
 }
 .gradient3{
    background: radial-gradient(circle at 48% 51%, rgba(16, 16, 16, 1) 0%, rgba(20, 183, 166, 0.44) 6%, rgba(0, 0, 0, 1) 62%);

    /* background:radial-gradient(circle at 50% 61%, rgba(16, 16, 16, 1) 0%, rgba(20, 183, 166, 0.44) 0%, hsla(0, 100%, 100%, 0.07) 56%) */
 
 }



 /* SimpleIntuitiveFeatures */
.simpleIntuitiveFeatures-container{
    padding: 2rem 0px;
    background-size:contain;
    width: 100%;
    background-position:top;
    background-repeat: no-repeat;
}
.sif-orangebg-left {
    background-color: #FF5C00;
    position: absolute;
    border: 1px solid green;
    left: 0;
    top: 47%;
    filter: blur(70px);
    border-radius: 999px;
    height: 179px;
    width: 48px;
    z-index: 1;
}
.sif-orangebg-right {
    background-color: #FF5C00;
    position: absolute;
    right: 0;
    top: 50%;
    filter: blur(70px);
    border-radius: 999px;
    height: 153px;
    width: 50px;
    z-index: 1;
}

.sif-h{
    font-size: 25px;
    font-weight: 500;
    line-height:normal ;
    color:rgba(255, 255, 255, 1);
}
.sif-p{
    font-size: 14px;
    font-weight: 400;
    line-height:normal ;
    color:rgba(255, 255, 255, 0.55);
}

.sif-card1-h{
    font-size: 1.3rem;
    font-weight: 500;
    line-height: normal;
    color: white;
}
.sif-card1-p{
    font-size: 13px;
    font-weight: 400;
   
    max-width: 85%;
    line-height: normal;
    color: rgba(255, 255, 255, 0.55);
}
.sif-main-img{
    max-width: 100%;
    height: 330px !important;
}
.sif-icon3{
    width: 24px;
    height: 24px;
}





/* start free trial */
.start-free-trial-container{
    width: 100%;
    padding: 2rem 0px;
  background-color: black;
}  

.start-free-trial-inner-container{
   width: 92%;
   margin: 0 auto;
   background-color: rgba(102, 34, 204, 1);
   padding: 2rem 1rem;
   border-radius: 16px;
   
}
.start-free-trial-right1{
    padding: 0rem 1rem;
}
.freetrialimg-container{
   
}
.freetrialimg{
    max-width: 100%;
    height: 129px;
   
}
.tryfree-btn-black{
  width: 135px;
  height: 44px;
  border-radius:999px ;
  background-color: rgba(23, 37, 39, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: rgba(226, 235, 237, 1);
}

.custom-list {
    color: white; /* This affects the text color */
    /* list-style: none; Remove default bullets */
}
/* Define the ::marker pseudo-element to change bullet color */
.custom-list li::marker {
    color: white; /* This changes the bullet color */
}


.sftc-left-h{
  color: white;
  font-weight:600 ;
  font-size: 28px;
  line-height: normal;
}
.sftc-left-p{
  color: white;
  font-weight:400 ;
  font-size: 14px;
  line-height: 24px;
}

.sftc-left-point{
  display: flex;
  justify-content: center;
  align-items: center;
 flex-wrap: wrap;
}
.sftc-point{
 width: 8px;
 height: 8px;
 border-radius:50%;
 background-color: white;
}
.sftc-text{
  color: white;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}
.sftc-left-point1{
  /* width: 33%; */
}


/* faqs */
.faqs-container{
    width: 100%;
    padding: 2rem 0px;
    background-color: black;
}
.faqs-inner-container{
    width: 86%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.faqs-inner-right{
    padding: 0px 12px;
}
.faqs-right{
    width: 50%;
}

.faqs-left{
    color: white;
}
.faqs-left-h p{
   font-size: 25px;
   font-weight: 500;
   line-height:normal;
}

/* Our Client */
.ourclient-container{
    padding: 2rem 0px;
    background-color: black;
    background-image: url('../../asets/asetes/bg-template.png'); /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.ourclient-content-top{
    padding: 10px;
}

.ourclient-h{
    font-size: 25px;
    font-weight: 500;
    line-height:normal ;
    color:white;
}
.ourclient-p{
    font-size: 14px;
    font-weight: 400;
    line-height:normal ;
    color:rgba(255, 255, 255, 0.55);
}

.client-feedback-container{
    max-width: 100%;
    border-radius: 12px;
    color: white;
}
.client-feedback-p{
    font-size: 14px;
    font-weight: 400;
    line-height:normal ; 
    text-align: justify;
}
.client-feedback-name{
    font-size: 16px;
    font-weight: 500;
    line-height:normal; 
    text-align: start;
}
.oc-love-text{
    background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}


}

/* .text-grd1 {
    
    background: #dc5300;
    background: linear-gradient(to right, #dc5300 18%, #FFFFFF 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */


/* footer */
.lp-footer-p-right {
    font-size: 14px;
  
    
}
.footer-privacy-term-of-use-text {
    font-size: 14px;
    font-family: "Instrument Sans", sans-serif;
}



@media screen and (min-width: 576px) and (max-width: 768px) {
    .bg-navbar{
        background:rgba(0, 0, 0);
    
    }
    .text-grd1 {
        
        background: #dc5300;
        background: linear-gradient(to right, #dc5300 18%, #FFFFFF 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .tryforfree-btn{
        width: 124.02px;
        height: 42px;
        border-radius: 999px;
        position: relative;
        background-image: linear-gradient(to right, #2F292B, #040102);
        border: none;
        color: white;
        font-size: 15.5px;
        font-weight: bold;
        overflow: hidden;
        cursor: pointer;
        outline: none; /* Remove outline when clicked */
    }

    .try-for-free-btn2{
        width: 80%;
    }
    
    
    /* border animation */
    .tryforfree-btn span:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 999px;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #171618, rgb(240, 122, 76));
        animation: animate1 2s linear infinite;
    }
    
    @keyframes animate1 {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
    
    .tryforfree-btn span:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        /* background: linear-gradient(to bottom, #171618, #3bff3b);
        animation: animate2 2s linear infinite;
        animation-delay: 1s; */
    }
    /* @keyframes animate2 {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(100%);
        }
    } */
    
    .tryforfree-btn span:nth-child(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, #171618, rgb(240, 122, 76));
        animation: animate3 2s linear infinite;
    }
    
    @keyframes animate3 {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    
    .tryforfree-btn span:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        /* background: linear-gradient(to top, #171618, #3bff3b);
        animation: animate4 2s linear infinite;
        animation-delay: 1s; */
    }
    
    /* @keyframes animate4 {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100%);
        }
    } */
    
    /*border animation tryfor free end */
    
    
    .partnerlogo{
        max-width: 81%;
        height: auto;
    }
    
    .hero-text-h{
        font-size: 32px;

        line-height: 42px;
        
    }
    .hero-text-p{
        font-size: 15px;
        font-weight: 400;
    }
    
    /* hero content */
    
    .hero-container-bg{
        min-height: 60vh;
        background: #36126D;
        background: radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 50%);
        background: -webkit-radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 50%);
        background: -moz-radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 50%);
    }

    .hero-container-px{
        padding-top: 6rem;
    }

    .magic-of-ai-btn1{
        width: 292.4px;
        height: 32px;
        border-radius: 999px;
        background-color: transparent;  
        color: white;
        font-size: 13.5px;
        font-weight: 400;
       
        line-height: 20px;
        outline: none;
    }
    .hero-content-container{
        margin-top: 70px;
    }




.getdemo-btn-outer{
    width: 80%;
}


    .get-demo-btn{
        width: 100%;
        height: 44px;
        border-radius: 999px;
      background-color: transparent;
        border: 1px solid rgb(38, 33, 33);
       color: white;
       font-size: 15px;
       font-weight: 500;
       line-height: 24px;
       outline: none;
    }
    .socialicon-inner-container{
        width: 180px;
    }
    .hero-text-h{
        font-size: 32px;
      
        line-height:42px ;
       
    }
    .hero-text-p{
        font-size: 15px;
        font-weight: 400;
        max-width: 522px;
       
       
    }
    
    .brand-logo-main-container{
       
        padding: 2rem 10px;
        
        /* background-color: rgba(9, 9, 9, 1); */
    }
    .brand-container{
        width: 96%;
        margin: 0 auto;
    }
    .logo-container{
        width: 60px;
        height: 60px;
        padding: 10px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    
    
    /* launch-camp */
    .launch-camp-container{
        padding: 1rem 0px;
    }
    .launch-camp-h{
        font-size: 36px;
     
        line-height: 46px;
       
    }
    .lcs-height{
    height:auto;
    }
    .gradient1{
        background:radial-gradient(circle at 50% 47%, rgba(16, 16, 16, 1) 0%, rgba(102, 34, 204, 1) 0%, rgba(0, 0, 0, 1) 59%);
    
    }
    .gradient2{
        background:radial-gradient(circle at 50% 47%, rgba(16, 16, 16, 1) 0%, rgba(255, 92, 0, 1) 0%, rgba(0, 0, 0, 1) 59%);
    
    }
    .gradient3{
        background:radial-gradient(circle at 50% 47%, rgba(16, 16, 16, 1) 0%, rgba(20, 183, 166, 1) 0%, rgba(0, 0, 0, 1) 59%);
    
    }
    
    
    
    
    
    .lcs1-left1{
        height: 364px;
        /* background: radial-gradient(circle at 48% 100%, rgba(16, 16, 16, 1) 0%, rgba(102, 34, 204, 1) 0%, rgba(0, 0, 0, 1) 53%); */
    }
    .lcs1-left2{
        height: 364px;
        /* background: radial-gradient(circle at 49% 100%, rgba(16, 16, 16, 1) 0%, rgba(255, 92, 0, 1) 0%, rgba(0, 0, 0, 1) 53%); */
    }
    .lcs1-left3{
        height: 364px;
        /* background: radial-gradient(circle at 48% 100%, rgba(16, 16, 16, 1) 0%, rgba(20, 183, 166, 1) 0%, rgba(0, 0, 0, 1) 53%); */
    }
    .lcs-img{
        height: 64px;
    }
    
    
    .lcs1-card-test1{
        font-size: 14px;
        font-weight: 400;
        line-height:20px ;
        color: white;
    }
    .lcs1-card-test2{
        font-size: 36px;
        font-weight: 500;
        line-height:44px ;
        color: white;
    }
    .lcs1-card-test3{
        font-size: 15.5px;
        font-weight: 400;
        line-height:24px ;
        color: white;
    }
    .lcs1-card-content{
        min-height: 170px;
    }
    
    
    
    .sticky-card{
        position:sticky;
        top:0
    }
    
    
    /* start free trial */
    .start-free-trial-container{
        width: 100%;
        padding: 3rem 0px;
      background-color: black;
    }  
    
    .start-free-trial-inner-container{
       width: 86%;
       margin: 0 auto;
       background-color: rgba(102, 34, 204, 1);
       padding: 4rem 3rem;
       border-radius: 16px;
       position: relative;
    }
    .freetrialimg-container{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50%;
        height: 361px;
    }
    .freetrialimg{
        max-width: 100%;
        height: 170px;
        object-fit: cover;
       
    }
    .tryfree-btn-black{
      width: 135px;
      height: 44px;
      border-radius:999px ;
      background-color: rgba(23, 37, 39, 1);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: rgba(226, 235, 237, 1);
    }
    
    
    
    
    .sftc-left{
        margin-top: 20px;
        max-height: 300px;
    }
    
    .sftc-left-h{
      color: white;
      font-weight:600 ;
      font-size: 40px;
      line-height: 40px;
    }
    .sftc-left-p{
      color: white;
      font-weight:400 ;
      font-size: 18px;
      line-height: 25px;
    }
    
    .sftc-left-point{
      display: flex;
      justify-content: space-between;
      align-items: center;
      
    }
    .sftc-point{
     width: 8px;
     height: 8px;
     border-radius:50%;
     background-color: white;
    }
    .sftc-text{
      color: white;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    .sftc-left-point1{
      width: 33%;
    }
    
.startfreetrial-img{
    height: 312px;
}



    /* faqs */
    .faqs-container{
        width: 100%;
        padding: 2rem 0px;
        background-color: black;
    }
    .faqs-inner-container{
        width: 86%;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
    
    }
    .faqs-inner-right{
        padding: 0px 12px;
    }
    .faqs-right{
        width: 50%;
    }
    
    .faqs-left{
        color: white;
    }
    .faqs-left-h p{
       font-size: 36px;
       line-height:normal;
    }
    
    
    /* important and outcome */
    .importantoutcome-container{
        padding: 3rem 0px;
        /* background: radial-gradient(circle at 0% 51%, rgba(255, 92, 0, 1) 0%, rgba(16, 30, 107, 1) 10%, rgba(0, 0, 0, 1) 26%); */
       background:radial-gradient(circle at 10% 48%, rgba(255, 92, 0, 1) -19%, #242c52 14%, rgba(0, 0, 0, 1) 33%)
    
    
    }
    .io-innercard1, .io-innercard2{
        width: 50%;
        height: 156px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        border: 1px solid rgb(85, 54, 54);
    }
    .io-innercard3{
        width: 100%;
        height: 156px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        border: 1px solid rgb(85, 54, 54);
       
    }
    
    
    /* border animation */
    .io-innercard1 span:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #171618, rgb(240, 122, 76));
        animation: animate1 2s linear infinite;
    }
    
    @keyframes animate1 {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
    
    .io-innercard1 span:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(to bottom, #171618, rgb(240, 122, 76));
        animation: animate2 2s linear infinite;
        animation-delay: 1s;
    }
    @keyframes animate2 {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(100%);
        }
    }
    
    .io-innercard1 span:nth-child(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, #171618, rgb(240, 122, 76));
        animation: animate3 2s linear infinite;
    }
    
    @keyframes animate3 {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    
    .io-innercard1 span:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(to top, #171618, rgb(240, 122, 76));
        animation: animate4 2s linear infinite;
        animation-delay: 1s;
    }
    
    @keyframes animate4 {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100%);
        }
    }
    
    /*border animation tryfor free end */
    
    
    /* border animation */
    .io-innercard2 span:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #171618, rgb(240, 122, 76));
        animation: animate1 2s linear infinite;
    }
    
    @keyframes animate1 {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
    
    .io-innercard2 span:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(to bottom, #171618, rgb(240, 122, 76));
        animation: animate2 2s linear infinite;
        animation-delay: 1s;
    }
    @keyframes animate2 {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(100%);
        }
    }
    
    .io-innercard2 span:nth-child(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, #171618, rgb(240, 122, 76));
        animation: animate3 2s linear infinite;
    }
    
    @keyframes animate3 {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    
    .io-innercard2 span:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(to top, #171618, rgb(240, 122, 76));
        animation: animate4 2s linear infinite;
        animation-delay: 1s;
    }
    
    @keyframes animate4 {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100%);
        }
    }
    
    /*border animation tryfor free end */
    
    /* border animation */
    .io-innercard3 span:nth-child(1) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #171618, rgb(240, 122, 76));
        animation: animate1 2s linear infinite;
    }
    
    @keyframes animate1 {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
    
    .io-innercard3 span:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(to bottom, #171618, rgb(240, 122, 76));
        animation: animate2 2s linear infinite;
        animation-delay: 1s;
    }
    @keyframes animate2 {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(100%);
        }
    }
    
    .io-innercard3 span:nth-child(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, #171618, rgb(240, 122, 76));
        animation: animate3 2s linear infinite;
    }
    
    @keyframes animate3 {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
    
    .io-innercard3 span:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(to top, #171618, rgb(240, 122, 76));
        animation: animate4 2s linear infinite;
        animation-delay: 1s;
    }
    
    @keyframes animate4 {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100%);
        }
    }
    
    /*border animation tryfor free end */
    
    
    
    
    /* imp and outcome */
    
    .importantoutcome-h{
        font-size: 36px;
      
        line-height:normal;
       
    }
    .importantoutcome-p{
        font-size: 16px;
        line-height:25px ;
        
    }
    .io-innercard1-h{
        font-size: 64px;
        font-weight: 600;
        line-height:64px ;
      
        color:rgba(255, 255, 255, 1);
        background: #121FCF;
        background: #F07A4C;
        background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
    }
    
    
    .io-innercard1-p{
        font-size: 16px;
        font-weight: 400;
        line-height:19px ;
        color:white;
    }
    
    
    
    /* SimpleIntuitiveFeatures */
    .simpleIntuitiveFeatures-container{
        padding: 2rem 0px;
    }
    .sif-h{
        font-size: 36px;
       
        line-height: 42px;
        
    }
    .sif-p{
        font-size: 15px;
        font-weight: 400;
        line-height:24px ;
        color:rgba(255, 255, 255, 0.55);
    }
    
    .sif-card1-h{
        font-size: 21px;
   
    line-height: 37px;
  
    }
    .sif-card1-p{
        font-size: 15px;
        font-weight: 400;
        line-height:24px ;
        color:rgba(255, 255, 255, 0.55);
    }
    
    
    
    /* Our Client */
    .ourclient-container{
        padding: 2rem 0px;
        background-color: black;
        background-image: url('../../asets/asetes/bg-template.png'); /* Adjust the path as needed */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    
    }
    .ourclient-content-top{
        padding: 10px;
    }
    
    .ourclient-h{
        font-size: 36px;
       
        line-height:44px ;
        
    }
    .ourclient-p{
        font-size: 16px;
       
        line-height:25px ;
        
    }
    
    .client-feedback-container{
        max-width: 70%;
        border-radius: 12px;
        color: white;
    }
    .client-feedback-p{
        font-size: 16px;
        font-weight: 400;
        line-height:24px ; 
    }
    .client-feedback-name{
        font-size: 20px;
        font-weight: 500;
        line-height:32px ; 
    }
    .oc-love-text{
        background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    
    
    .preview-video-container{
      
       min-height: 55vh;
       background: radial-gradient(circle at 50% -6%, rgba(102, 34, 204, 0.7) -25%, rgba(0, 0, 0, 1) 59%);
       color: white;
       
    
    }
    .prev-h{
        font-size: 36px;
       
        line-height:44px ;
    }
    .prev-p{
        font-size: 16px;
        font-weight: 400;
        line-height:24px ; 
    }
    
    .moving-start{
        position: absolute;
        
    }
    
    .preview-img-container{
       overflow: hidden;
    
    }
    
    
    
    /* footer */
    
    .brand-logo-leapx{
    
    }
    .lp-footer-container{
        background-color: black;
        padding: 2rem 0px;
        opacity: 0.8;
    }
    
    
    
    
    
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    .lp-footer-container{
        background-color: black;
        padding: 2rem 0px;
        opacity: 0.8;
    }
  }


  @media screen and (min-width: 992px) and (max-width: 1200px) {
    
  }
