/* form card  */

/* for chip box */
/* .MuiButtonBase-root{
    background-color: #DE3820 !important;
    color: rgb(206, 201, 201) !important;
  } */
/* .MuiSvgIcon-root{
    color: white !important;
  } */
.MuiChip-filled {
  background-color: #6622cc !important;
}
.MuiChip-deleteIcon {
  color: white !important;
}

/* date picker */
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper input {
  width: 100%;
  padding: 10px 8px !important;
  border: none !important;
  outline: none !important;
  border-color: #66f;
}
.addMore-btn {
  background-color: #6622cc;
  padding: 10px 8px;
  position: absolute;
  right: 2px;
  top: 5px;
  width: 110px;
  height: 38px;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addMore-btn .css-i4bv87-MuiSvgIcon-root {
  /* font-size: 20px !important; */
  margin: 0;
  width: 24px !important;
  height: 24px !important;
}
.addMore-btn p {
  font-size: 14px !important;
  color: #ffffff;
  margin: 0;
}
.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
}

/* Input field styles */
.input-container input {
  border: none;
  outline: none;
  flex: 1;
  padding: 8px;
}

/* multy file upload */
/* Style for the file input container */

.file-icon {
  margin-right: 10px;
  color: #888;
  cursor: pointer;
}
.file-input-container {
}
/* .file-input-container:hover {
  border-color: #66f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

.previews {
  display: flex;
  flex-direction: column;
}

.preview-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

/* Set the height and width of the preview images */
.preview-item img {
  height: 170px;
  width: 200px;
}

.remove-button {
  margin-left: 10px;
  cursor: pointer;
}

/* end */

/* Icon styles */
.input-container i {
  margin-right: 10px;
  color: #888;
}

/* Hover effect */
/* .input-container:hover {
  border-color: #66f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */
/* file */
.file-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 14px 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, border-color 0.3s;
  justify-content: flex-start;
}
.custom-input::placeholder {
  color: #828282; /* Set your desired color for the placeholder text */
  font-size: 12px;
}
.checkbox .css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 1rem;
  letter-spacing: 0.00938em;
  color: #000;
}
.checkbox1 .css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #828282;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.heading-Property {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: left !important;
  margin-bottom: 10px;
}
.textarea-container textarea {
  border: none;
  outline: none;
  flex: 1 1;
  padding: 26px 15px !important;
}
.textInput-qp {
  color: #262626;
  font-size: 12px;
  margin-left: 5px;
}
.input-container .react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 96% !important;
}
.textInput-q::placeholder {
  color: #828282; /* Set your desired color for the placeholder text */
  font-size: 12px;
}
.placeholder-creatives {
  color: #828282; /* Set your desired color for the placeholder text */
  font-size: 12px;
  margin-left: 15px;
  margin: 0;
  text-align: left;
}
.textInput-qp::placeholder {
  color: #828282; /* Set your desired color for the placeholder text */
  font-size: 12px;
}
.textInput-q {
  color: #262626;
  font-size: 12px;
  margin-left: 10px;
}
.input-container .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}
.creative-location {
  color: #828282;
  font-size: 10px;
  margin: 0;
  text-align: left;
}
.heading-Property1 {
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
}
.css-1hv8oq8-MuiStepLabel-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px !important;
  height: 30px !important;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.38);
}
/* File input styles (hidden) */
.file-input-container input[type="file"] {
  display: none;
}

/* Icon styles */
.file-input-container .file-icon {
  margin-right: 10px;
  color: #888;
  cursor: pointer;
}
.margintop{
   margin-top: 2vh;
}
/* Hover effect */
/* .file-input-container:hover {
  border-color: #66f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

/* text area */
.textarea-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, border-color 0.3s;
}

/* Textarea styles */
.textarea-container textarea {
  border: none;
  outline: none;
  flex: 1;
  padding: 8px;
}

/* Icon styles */
.textarea-container i {
  margin-right: 10px;
  color: #888;
}
ol,
ul {
  padding-left: 0rem !important;
}
.p-slider .p-slider-range {
  background: #f07a4c;
}
/* Hover effect */
.textarea-container:hover {
  border-color: #66f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.min-max {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.min-max p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #000000;
}
.min-max h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  text-align: right;
}
/* step */

/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #6622cc !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #6622cc !important;
} */


/* .css-6od3lo-MuiChip-label {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  
} */
.button-pill {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 6px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.submit-button5 {
  background-color: #6622cc;
  width: 240px;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
/* .css-6od3lo-MuiChip-label {
  font-size: 12px !important;
} */
.input-container
  .css-uqjlkp-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-root
  input {
  font-size: 12px !important;
}

.image-upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  flex-wrap: wrap;
}

.upload-box {
  width: 88px;
  height: 60px;
  background-color: #6622cc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plus-icon {
  color: white;
  font-size: 24px;
}

.image-thumbnail-container{
    position: relative;
}
.delete-preview{
  position: absolute;
  top: -8px;
  right: -6px;
  cursor: pointer;
  z-index: 1;
}

.image-thumbnail {
  width: 88px;
  height: 60px;
  
  border-radius: 10px;
}

.delete-button {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 15px;
  height: 15px;
  padding: 3px;
  border: none;
  border-radius: 50%;
  background-color: #414141;
  color: white;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#reviewDetails .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.validationFailed {
  border: 1px solid red;
}

@media only screen and (max-width: 601px){
    .margintop{
         margin-top: 1vh;
    }
    .review-submit-btn-container{
      margin-bottom: 12vh;
  }
}
