ul.current-location-list li{
    list-style-type:initial !important;
}
.MuiAutocomplete-inputRoot{
    border-radius: 0px;
    border-color:white;
}

.autocomplete-option{
    color: gray;
}
.autocomplete-option:hover{
    color: white;
    background-color: #6622cc;
}
.selected-location-box{
    min-height: 40px;
    max-height: 90px;
}


.show-hide-deletedselectLocation{
    visibility: hidden;
}
.selectedlocationlist:hover .show-hide-deletedselectLocation{
      visibility: visible;
}
.selected-location-text{
    font-size: 14px;
}

.summary-h3 h3{
     font-size: 1.3rem !important;
}
.summary-h3 ol{
    margin-left: 1.2rem !important;
}

/* primereact slider */
.p-slider .p-slider-handle{
    border: 2px solid #6366F1 !important;
}

/* incre dec budget */
/* Increment and decrement budget buttons */
.button-inc {
    font-size: 24px;
    font-weight: 600;
    padding: 2px;
    color: black;
    width: 100px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.button-inc:hover {
    background-color: #6622cc;
    color: white;
    transform: scale(1.1); /* Slightly increase the size on hover */
}

.border-inc {
    border-right: 2px solid rgb(207, 202, 202);
}

.border-dec {
    border-left: 2px solid rgb(207, 202, 202);
}

.border-icndec-container {
    border: 2px solid rgb(207, 202, 202);
    border-radius: 20px;
    width: 300px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
}

.border-icndec-container:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}

/* Input budget field */
.input-budget-inc {
    outline: none;
    text-align: center;
    border-top: none;
    font-size: 24px;
    border-left: none;
    border-right: none;
    font-weight: 700;
    min-width: 50%;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.input-budget-inc:focus {
    border-color: #6622cc; /* Change border color on focus */
    background-color: #f0f0f0; /* Slightly change background on focus */
}

.min-max{
    width: 300px;
}

/* Optional: Add keyframe animations */
@keyframes buttonHover {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

@keyframes containerHover {
    from {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    to {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
}



@media(max-width:768px){
    .show-hide-deletedselectLocation{
        visibility:visible;
    }
    .include-exclude-width{
        width: 100%;
    }
    .selected-location-text{
        font-size: 10px;
    }
    .selected-location-box{
        height: auto;
        padding: 5px 0px;
    }
    .select-include-exclude{
        width: 100%;
    }

    .confirmandpay-btn{
        margin-bottom: 14vh;
    }
    /* inc dec budget */
    .button-inc{
      font-size: 20px;
    }
    .input-budget-inc {
        font-size: 20px;
    }

    .border-icndec-container{
      width: 100%;
    }

    .min-max{
        width: 100%;
    }
}


    