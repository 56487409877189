.bg-navbar{
    background:rgb(0, 0, 0);
    
}

.try-for-free-btn {
    width: 164px;
    height: 44px;
    font-family: "Instrument Sans", sans-serif;
    border-radius: 999px;
    background: linear-gradient(207deg, rgb(47, 41, 43) -70.344%, rgb(4, 1, 2) 100%);
   font-weight: 600;
    color: white;
    text-shadow: 0 1px 0 #000;
    
    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        #213,
        #112 5%,
        #112 60%,
        #213 95%
      );
    
    border: solid 1px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #fff, transparent);
    
    background: 
      var(--main-bg) padding-box,
      var(--gradient-border) border-box, 
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
  }
  
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  
  .box:hover {
    animation-play-state: paused;
  }
  
  /* Property definition for --border-angle */
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  


.tryforfree-btn{
    width: 164px;
    height: 44px;
    font-family: "Instrument Sans", sans-serif;
    border-radius: 999px;
    position: relative;
    background: linear-gradient(207deg, rgb(47, 41, 43) -70.344%, rgb(4, 1, 2) 100%);
    border:1px solid gray;

    color: white;
    opacity: 1;
    font-size: 15.5px;
    font-weight: bold;
    overflow: hidden;
    cursor: pointer;
    outline: none; /* Remove outline when clicked */
}

.tryforfree-btn:hover{
   
    border:1px solid white;

    
}


/* border animation */
.tryforfree-btn span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
    border-radius: 999px;
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, #171618, rgb(240, 122, 76));
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.tryforfree-btn span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 1px;
	
}


.tryforfree-btn span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to left, #171618, rgb(240, 122, 76));
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.tryforfree-btn span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1px;
	
}


.hero-text-h{
   font-size: 55.02px;
   font-weight: 500;
   line-height: 64px;
   color: white;
   font-family: "Instrument Sans", sans-serif;
}
.hero-text-p{
    font-size:16px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
}

/* hero content */

.hero-container-bg{
    min-height: 60vh;
    background: #36126D;
    background: radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 30%);
    background: -webkit-radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 30%);
    background: -moz-radial-gradient(circle farthest-side at top center, #36126D 0%, #0c0c0c 30%);
}
.hero-container-px{
    padding-top: 7rem;
}

.hero-dotted-bgimg{
   width: 99%;
   opacity: 0.2;
  
   z-index: 1; 
}
.magic-of-ai-btn1{
    width: 292.4px;
    height: 32px;
    border-radius: 999px;
    background-color: transparent;
    /* background-color: rgba(255, 255, 255, 0.08);   */
    color: white;
    box-shadow: rgba(255, 255, 255, 0.08) 0px 1px 4px 1px inset, rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset;
    opacity: 999;
    overflow: hidden;
    border: none;
    font-size: 13.5px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height: 20px;
   
}
.hero-content-container{
    margin-top: 125px;
}
.get-demo-btn{
    width: 164px;
    height: 44px;
    font-family: "Instrument Sans", sans-serif;
    border-radius: 999px;
  background-color: transparent;
    /* border: 1px solid rgb(38, 33, 33); */
   color: white;
   border: none;
   
   font-size: 15px;
   font-weight: 500;
   line-height: 24px;
   outline: none;
}

.get-demo-btn:hover{
    background: rgba(255, 255, 255, 0.08);
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
   border: 1px solid rgb(38, 33, 33);
}


.socialicon-inner-container{
    width: 180px;
}
.hero-text-h{
    font-size: 55px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    line-height:64px ;
    color: white;
}
.hero-text-p{
    font-size: 16px;
    font-weight: 400;
    max-width: 522px;
    font-family: "Instrument Sans", sans-serif;
    line-height:24px ;
    color: rgba(255, 255, 255, 0.55);
}

.brand-logo-main-container{
   
    padding: 1.8rem 10px;
    
    /* background-color: rgba(9, 9, 9, 1); */
}
.importantoutcome-p{
    top: -20px;
    width: 99%;
}

.partnerlogo{
    max-width: 55%;
    height: auto;
}

.brand-container{
    width: 96%;
    margin: 0 auto;
}
.logo-container{
    width: 100px;
    height: 100px;
    padding: 10px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


/* launch-camp */
.launch-camp-container{
    padding: 4rem 0px;
}
.launch-camp-h{
    font-size: 42px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    color: white;
}
.lcs-height{
height: 364px;
}
.gradient1 {
    background: radial-gradient(circle at 25% 100%, rgba(16, 16, 16, 1) 0%, #dc5300 0%, rgba(0, 0, 0, 1) 23%);
}



.gradient3{
    background: radial-gradient(circle at 30% 100%, rgba(16, 16, 16, 1) 0%, rgba(20, 183, 166, 1) 0%, rgba(0, 0, 0, 1) 33%);
}
.gradient2{
    background: radial-gradient(circle at 64% 121%, rgba(255, 92, 0, 1) -67%, rgba(0, 0, 0, 1) 51%);
     

}






.lcs1-left1{
    height: 364px;
    position: relative;
    /* background: radial-gradient(circle at 48% 100%, rgba(16, 16, 16, 1) 0%, rgba(102, 34, 204, 1) 0%, rgba(0, 0, 0, 1) 53%); */
}

.lcs1-bluebg-box{
    position: absolute;
    background-color: #6622CC;
    bottom: 0;
    left:0%;
    filter: blur(140px);
    height: 135px;
    z-index: -1;
    border-radius: 20px 20px 0px 0px;
    width: 600px;
}
.lcs2-bluebg-box2{
    position: absolute;
    background-color:#FF5C00;
    bottom: 0;
    left:0%;
    filter: blur(140px);
    height: 135px;
    z-index: -2;
    border-radius: 20px 20px 0px 0px;
    width: 600px;
}
.lcs3-bluebg-box3{
    position: absolute;
    background-color: #14B7A6;
    bottom: 0;
    left:0%;
    filter: blur(140px);
    height: 135px;
    z-index: -3;
    border-radius: 20px 20px 0px 0px;
    width: 600px;
}



.lcs1-left2{
    height: 364px;
    position: relative;
    /* background: radial-gradient(circle at 49% 100%, rgba(16, 16, 16, 1) 0%, rgba(255, 92, 0, 1) 0%, rgba(0, 0, 0, 1) 53%); */
}
.lcs1-left3{
    height: 364px;
    position: relative;
    /* background: radial-gradient(circle at 48% 100%, rgba(16, 16, 16, 1) 0%, rgba(20, 183, 166, 1) 0%, rgba(0, 0, 0, 1) 53%); */
}
.lcs-img{
    height: 64px;
}


.lcs1-card-test1{

    font-size: 14px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height:20px ;
    color: white;
}
.lcs1-card-test2{
   
    


    font-size: 36px;
    font-weight: 500;
    line-height:44px ;
    font-family: "Instrument Sans", sans-serif;
    color: white;
}
.lcs1-card-test3{
    


    font-size: 15.5px;
    font-weight: 400;
    line-height:24px ;
    font-family: "Instrument Sans", sans-serif;
    color: white;
}
.text-grd1{
    background: #dc5300;
    background: linear-gradient(to right, #dc5300 0%, #FFFFFF 26%) ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-grd2{
    background: #FF5C00;
    background: linear-gradient(to right, #FF5C00 0%, #FFFFFF 26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-grd3{
    background: #14B7A6;
    background: linear-gradient(to right, #14B7A6 0%, #FFFFFF 26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}





.inner-lcs1-right{
    position: relative;
}




.lcs1-card-content{
    min-height: 170px;
    position: relative;
}

.lcs-stick1{
    position: absolute;
    left: -46px;
    bottom: 53px;
}


.sticky-card{
    position:sticky;
    top:0
}


/* start free trial */
.start-free-trial-container{
    width: 100%;
    padding: 4rem 0px;
  background-color: black;
}  

.startfreetrial-container{
    width: 100%;
    height: 456px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.startfreetrial-img{
    width: 100%;
    height: 456px;
    object-fit: cover;
}

.start-free-trial-inner-container{
   width: 86%;
   margin: 0 auto;
   background-color: rgba(102, 34, 204, 1);
max-height: 456px;
   display: flex;
   justify-content: space-between;
   border-radius: 16px;
   position: relative;
}

.start-free-trial-right1{
    padding: 4rem 2rem;
}
.freetrialimg-container{
    /* position: absolute; */
   
    width: 50%;
    max-height:100%;
}
.freetrialimg{
    max-width: 100%;
    max-height: 456px;
   
}



.tryfree-btn-black{
  width: 135px;
  height: 44px;
  border-radius:999px ;
  background-color: rgba(23, 37, 39, 1);
  font-size: 16px;
  font-family: "Instrument Sans", sans-serif;
  font-weight: 600;
  line-height: 20px;
  color: rgba(226, 235, 237, 1);
}

.try-for-free-btn2 {
    width: 164px;
    height: 44px;
    font-family: "Instrument Sans", sans-serif;
    border-radius: 999px;
    background: linear-gradient(207deg, rgb(47, 41, 43) -70.344%, rgb(4, 1, 2) 100%);
    color: white;
    text-shadow: 0 1px 0 #000;
    font-weight: 600;
    --border-angle: 0turn;
    --main-bg: conic-gradient( from var(--border-angle), #213, #112 5%, #112 60%, #213 95% );
    border: solid 1px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #fff, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
    font-size: 15px;
}




.sftc-left-h{
  color: white;
  font-weight:600 ;
  font-size: 56px;
  font-family: "Instrument Sans", sans-serif;
  line-height: 64px;
}
.sftc-left-p{
  color: white;
  font-weight:400 ;
  font-size: 16px;
  font-family: "Instrument Sans", sans-serif;
  line-height: normal;
}

.sftc-left-point{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding-left: 4px;
 
}
.sftc-point{
 width: 8px;
 height: 8px;
 border-radius:50%;
 background-color: white;
}
.sftc-text{
  color: white;
  font-size: 15px;
  font-weight: 600;
  font-family: "Instrument Sans", sans-serif;
  line-height: 20px;
}
.sftc-left-point1{

 
}

/* faqs */
.faqs-container{
    width: 100%;
    padding: 4rem 0px;
    background-color: black;
}
.faqs-inner-container{
    width: 86%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.faqs-inner-right{
    padding: 0px 12px;
}
.faqs-right{
    width: 50%;
}

.faqs-left{
    color: white;
}
.faqs-left-h p{
   font-size: 42px;
   font-weight: 500;
   font-family: "Instrument Sans", sans-serif;
   line-height: 56px;
}


/* important and outcome */
.importantoutcome-container{
    padding: 4rem 0px;
    position: relative;
    /* background: radial-gradient(circle at 0% 51%, rgba(255, 92, 0, 1) 0%, rgba(16, 30, 107, 1) 10%, rgba(0, 0, 0, 1) 26%); */
   /* background:radial-gradient(circle at 10% 48%, rgba(255, 92, 0, 1) -19%, #242c52 14%, rgba(0, 0, 0, 1) 33%) */
    background-color: black;

}
.iao-bluebg{
    position: absolute;
    height: 244px;
    width: 122px;
    top: 0;
    filter: blur(100px);
    background-color: rgba(102, 34, 204, 0.6);
    left: 0;
    z-index: 1;
}
.iao-orangebg{
    position: absolute;
    height: 228px;
    width: 228px;
    border-radius: 999px;
    top: 155.05px;
    filter: blur(100px);
    background-color: rgba(240, 122, 76, 0.6);
    left: 0;
    z-index: 2;
    
}

.impactnadoutcome-b{
    /* background-image: url("../../asetes//impactoutcome\ bg.svg"); */
    background-size: cover; 
    background-position: center center;
    background-repeat: no-repeat;
    
    margin: 0;
    padding: 0;
}




.io-innercard1, .io-innercard2{
    width: 50%;
    height: 156px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid rgb(85, 54, 54);
}
.io-innercard3{
    width: 100%;
    height: 156px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid rgb(85, 54, 54);
   
}


/* border animation */
.io-innercard1 span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, #171618, rgb(240, 122, 76));
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.io-innercard1 span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 1px;
	background: linear-gradient(to bottom, #171618, rgb(240, 122, 76));
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}
@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

.io-innercard1 span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to left, #171618, rgb(240, 122, 76));
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.io-innercard1 span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1px;
	background: linear-gradient(to top, #171618, rgb(240, 122, 76));
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}

/*border animation tryfor free end */


/* border animation */
.io-innercard2 span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, #171618, rgb(240, 122, 76));
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.io-innercard2 span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 1px;
	background: linear-gradient(to bottom, #171618, rgb(240, 122, 76));
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}
@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

.io-innercard2 span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to left, #171618, rgb(240, 122, 76));
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.io-innercard2 span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1px;
	background: linear-gradient(to top, #171618, rgb(240, 122, 76));
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}

/*border animation tryfor free end */

/* border animation */
.io-innercard3 span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, #171618, rgb(240, 122, 76));
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.io-innercard3 span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 1px;
	background: linear-gradient(to bottom, #171618, rgb(240, 122, 76));
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}
@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

.io-innercard3 span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background: linear-gradient(to left, #171618, rgb(240, 122, 76));
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.io-innercard3 span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1px;
	background: linear-gradient(to top, #171618, rgb(240, 122, 76));
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}

/*border animation tryfor free end */




/* imp and outcome */

.importantoutcome-h{
    font-size: 42px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    line-height:51px ;
    color: white;
}
.importantoutcome-p{
    font-size: 16px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height:28px ;
    color:rgba(255, 255, 255, 0.55);
}
.io-innercard1-h{
    font-size: 64px;
    font-weight: 600;
    font-family: "Instrument Sans", sans-serif;
    line-height:64px ;
  
    color:rgba(255, 255, 255, 1);
    background: #121FCF;
    background: #F07A4C;
    background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}


.io-innercard1-p{
    font-size: 16px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height:19px ;
    color:white;
}



/* SimpleIntuitiveFeatures */
.simpleIntuitiveFeatures-container{
    padding: 4rem 0px;
    position: relative;
    background-color: black;
    background-image: url('../../asets/asetes/bg-template.png'); /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.simplebg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
}
.sif-orangebg-left{
    background-color: #FF5C00;
    position: absolute;
    left: 0;
    top: 35%;
    filter: blur(86px);
    border-radius: 999px;
    height: 334px;
    width: 37px;
z-index: 1;
}
.sif-orangebg-right{
    background-color: #FF5C00;
    position: absolute;
    right: 0;
    top: 35%;
    width: 37px;
    filter: blur(86px);
    /* filter: blur(181px); */
    border-radius: 999px;
    height: 334px;
    /* width: 154px; */
z-index: 1;
}


.sif-h{
    font-size: 42px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    color:rgba(255, 255, 255, 1);
}
.sif-p{
    font-size: 16px;
    font-weight: 400;
    line-height:24px ;
    font-family: "Instrument Sans", sans-serif;
    color:rgba(255, 255, 255, 0.55);
    
}

.sif-card1-h{
    font-size: 1.5rem;
    font-weight: 500;
    line-height:32px ;
    color:white;
}
.sif-card1-p{
    font-size: 15px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height:24px ;
    color:rgba(255, 255, 255, 0.55);
}



/* Our Client */
.ourclient-container{
    padding: 4rem 0px;
    background-color: black;
    background-image: url('../../asets/asetes/bg-template.png'); /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.ourclient-content-top{
    padding: 10px;
}

.ourclient-h{
    font-size: 42px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    color:white;
}
.ourclient-p{
    font-size: 16px;
    font-weight: 400;
    margin-top:4px;
    line-height:24px ;
    font-family: "Instrument Sans", sans-serif;
    color:rgba(255, 255, 255, 0.55);
}

.client-feedback-container{
    max-width: 70%;
    border-radius: 12px;
    color: white;
}
.client-feedback-p{
    font-size: 14px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height:24px ; 
}
.client-feedback-name{
    font-size: 20px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    line-height:32px ; 
}
.oc-love-text{
    background: linear-gradient(to right, #F07A4C 0%, #6622CC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.preview-img-grad{
    background-image: linear-gradient(
        0deg,
        hsl(240deg 100% 20%) 0%,
        hsl(240deg 100% 14%) -1%,
        hsl(0deg 0% 0%) 72%
      );
     
}
.preview-video-container{
  
   padding: 4rem 0px 2rem 0px;
   /* background: radial-gradient(circle at 50% -6%, rgba(102, 34, 204, 0.7) -25%, rgba(0, 0, 0, 1) 59%); */
   background: radial-gradient(circle at 50% -6%, rgba(102, 34, 204, 0.7) -25%,  #0a0a0a 59%);
   
   color: white;
   position: relative;

}


.prev-h{
    font-size: 42px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
    color:white;
}
.prev-p{
    font-size: 16px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
    line-height:24px ;   
    color: rgba(255, 255, 255, 0.55);
}

/* .preview-gradient-bg{
    background-color: rgba(102, 34, 204, 0.7);
    width: 60%;
    height: 40px;
    position: absolute;
    left: 20%;
    bottom: 20px;
    filter: blur(10px);
    opacity: 1;
} */

.previewbg-img{
    position: absolute;
    max-width: 100%;
    z-index: 9;
    max-height: 90vh;
}
.moving-start{
    position: absolute;
    
}

.preview-img-container{
   overflow: hidden;
   position: absolute;
   z-index: 999;
   bottom: 0;
   border-radius:400px  400px 0px 0px;
   border: 1px solid gray;
}



/* footer */

.brand-logo-leapx{

}
.lp-footer-container{
    background-color: black;
   
}
.lp-footer-containe-inner{
    padding: 4rem 0px;
    position: relative;
}
.lp-footer-p-right{
    font-size: 16px !important;
    font-family: "Instrument Sans", sans-serif;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.55);
    font-weight: 400;
}

.lp-footer-right-h{
    font-size: 16px;
    font-family: "Instrument Sans", sans-serif;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
}
.lp-footer-right-h{
    font-size: 16px;
    font-family: "Instrument Sans", sans-serif;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
}
.lp-footer-right-p{
    font-size: 14px;
    font-family: "Instrument Sans", sans-serif;
    line-height: 21px;
    color: rgba(255, 255, 255, .55);
    font-weight: 400;

}

.lp-footer-socialtext-h{
    font-size: 16px;
    font-family: "Instrument Sans", sans-serif;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;

}
.footer-bottom-section{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.footer-privacy-term-of-use-text{
    color: white;
    font-weight: 400;
    font-size: 15px;
    font-family: "Instrument Sans", sans-serif;
}

.client-feedback-container{
    background-color: rgba(50, 50, 50, 1);
    position: relative;
    width: 90%;
}
.client-circle-photo{
    position: absolute;
    left: -34px;
    top: 2px;
}

.opacity-foote-social {
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Added transform to the transition */
}

.opacity-foote-social:hover {
    opacity: 1;
    transform: translateY(-2px);
}
