.calendar-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
  }
  
  .calendar-container.open {
    max-height: 700px; /* Adjust based on the expected height of the calendar */
    opacity: 1;
  }
  
  
  
  .custom-calendar .rdp .rdp-day_selected {
    background-color: #62c !important; /* Add selected day background color */
    color: #fff !important; /* Ensure text is visible */
  }

  button.bg-primary {
    background-color: #62c !important;

}

  /* .rdp-button_reset{
    font-size: 0.8rem !important;
    width: 1.4rem !important;
    height: 1.4rem !important;
    margin-right: 2px;
  }
  .rdp-buttn{
    font-size: 0.8rem !important;
  }
   

  button.bg-primary {
    background-color: #62c !important;

}
.rdp-head tr th{
    font-size: 0.8rem !important;
    margin-right: 2px;
    width: 1.4rem !important;
    height: 1.4rem !important;
} */

/* add budget */

.input-add-budget::placeholder {
  font-size: 16px;
}

.input-add-budget {
  font-size: 16px;
}

/* Remove default border and add border on focus */
.input-add-budget {
  border: 1px solid gray; /* initial gray border */
}

.input-add-budget:focus {
  outline: none;
  border: 2px solid blue; /* border color on focus */
}