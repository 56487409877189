.viewLeadCard {
  /* border: 1px solid black; */
  border-radius: 16.617px;
  background: #efebf4;
  /* background: linear-gradient(111.53deg, #2e1843 0%, #492261 94.26%); */
  font-family: "DM Sans", sans-serif;
  padding: 12px 17px;
}
.viewLeadCard .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
}
.viewLeadCard .header-left {
  display: flex;
  gap: 3px;
  align-items: center;
}
.viewLeadCard .header .header-left-right {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  /* gap: 5px; */
}
.viewLeadCard .header .header-left-right h3 {
  font-size: 16px;
  font-weight: 500;
}
.viewLeadCard .header .header-right {
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  font-size: 12.463px;
  font-style: normal;
  line-height: normal;
}
.viewLeadCard .header .header-right .row1 span {
  font-weight: 700;
}
.viewLeadCard .header .header-right .row2 {
  display: flex;
  justify-content: space-between;
}
.viewLeadCard .detail-left {
  display: flex;
  align-items: center;
  gap: 5px;
}
.viewLeadCard .detail-left-right {
  display: flex;
  flex-direction: column;
}
.viewLeadCard .detail-left-right p:first-of-type {
  margin-bottom: 0;
  font-size: 12px;
  color: #959595;
}
.viewLeadCard .detail-left-right p:last-of-type {
  /* font-size: 24.926px; */
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  display: flex;
  gap: 2px;
}
.viewLeadCard .detail-left-right p:last-of-type span {
  font-size: 12px;
  color: #00cb14;
}

/* .uncoloured {
  color: #c8c6cb !important;
} */
.circle {
  background-color: black;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}
.finalResult {
  font-size: 11.414px;
}
.finalResult-one p {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.finalResult .finalResult-one .finalResult-one-one {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}
.finalResult .finalResult-one .finalResult-one-one span {
  opacity: 0.5;
  font-weight: 500;
}
/* .viewLeadCard .btn {
  padding: 8px;
  background-color: black;
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 23px;
}
.viewLeadCard .btn:active {
  background-color: black;
} */
