.leadtabl tr td{
      font-size: 16px;
      font-weight: 400;
      text-align: start;
      padding: 10px;
}
.leadtabl tr th{
    text-align: start;
    padding: 10px;
}
.table-color-bold{
     color: black;
     font-weight: 700;
}
 .searchBox {
    height: 40px;
    min-width: 25ch;
    width: 40px;
    width: 40%;
    border-radius: 8px;
    background: rgba(230, 230, 230, 0.5);
    padding-inline: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0;
  }
   .searchBox input {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    color: #949494;
  }
  .table-web tr th{
     font-size: 14px !important;
     font-weight: 700;
  }
  .table-web tr td{
    font-size: 14px !important;
     font-weight: 400;
  }

  .table-hover-web:hover{
   background-color: #b7b5bb;
 
}

.table-hover-web:hover td{
   color: white ;
}

.table-td-padding{
 padding: 14px 8px !important;
}
