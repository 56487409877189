@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");
/* .Mobile-View {
  display: none;
} */


  /* .Mobile-View {
    display: block;
  } */
  .background-top {
    /* background-image: url("../asets/background.png"); */

    /* background-image: url("../asets/home_background.png"); */
    background-image: url("../asets/home_background.svg");

    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
    width: 100%;
    padding-top: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .box-home {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .welcome {
    padding: 0px 20px;
  }
  .welcome h6 {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
  .welcome p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
  }
  .balance h6 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0px 0px 0px;
  }
  .active-camp-text{
    font-size: 14px;
   
  }
  .balance p {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
  }
  .company-name h6 {
    /* color: #ffffff8e; */
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
  .company-name p {
    color: #ffffff8e;
    font-size: 10px;
    font-weight: 400;
  }
  .wallet {
    max-height: 240px;
    min-height: 180px;
    border-radius: 20px;
    /* background-color: rgb(0, 0, 0, 0.6); */
    background-image: url("../asets//welcomeCard.svg");
    width: 90%;
    margin: 0px 20px;
    /* margin-top: -25%; */
    /* margin-top: -120px; */
    margin-top: -74px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-size: cover;

    align-items: center;
  }

  .walletweb {
    height: auto;
    border-radius: 20px;
    /* background-color: rgb(0, 0, 0, 0.6); */
    background-image: url("../asets//welcomeCard.svg");
    width: 90%;
    margin: 0px 20px;
    /* margin-top: -25%; */
    /* margin-top: -120px; */
    margin-top: -74px;
    padding: 20px;
    
    background-size: cover;

    align-items: center;
  }




  .wallet-2 {
    height: 210px;
    border-radius: 20px;
    /* background: linear-gradient(111.53deg, #2e1843 0%, #492261 94.26%); */
    background-color: #f3eff8;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .slider {
    display: flex;
    align-items: center;
    width: 58%;
    height: 100%;
 
  }
  .company-name {
    margin-top: 25px;
  }
  .spend {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .spend .spent-heading {
    display: flex;
    align-items: center;
    color: #fff;
    gap: 5px;
  }
  .spend h6 {
    font-size: 11px;
    color: #ffffff;
    /* color: black; */
    margin: 0;
    font-weight: 500;
    /* opacity: 0.5; */
  }
  .spend h6 span {
    opacity: 0.8;
  }
  .spend div.circle-type {
    background-color: white;
  }

  .spend p {
    font-size: 10px;
    color: #ffffff;
    margin: 0;
    padding: 0px 10px;
    font-weight: 500;
  }
  .overview {
    background-color: #ede9ff;
    margin: 20px;
    border-radius: 16px;
    padding: 10px;
  }
  .overview h1 {
    font-size: 20px;
    color: #6622cc;
    margin: 0;
    font-weight: 700;
  }
  .total-box {
    /* width: 48%; */
    width: 100%;
    background-color: #e6dffc;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    border-radius: 10px;
  }
  .total-box-2 {
    width: 48%;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    border-radius: 10px;
  }
  .overview-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    gap: 10px;
  }
  .total-box h6 {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 0;
  }
  .total-box sup {
    font-size: 12px;
    font-weight: 500;
    color: #00ab11;
    margin: 0;
  }
  .total-box p {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }
  .total-box-2 h6 {
    font-size: 12px;
    font-weight: 500;
    /* color: #fff; */
    color: black;
    margin: 0;
  }
  .total-box-2 sup {
    font-size: 12px;
    font-weight: 500;
    color: #00ab11;
    margin: 0;
  }
  .total-box-2 p {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }
  .icon_box {
    width: 16px;
    height: 16px;
    background-color: #e0d6fa;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    opacity: 0.7;
  }
  .box-icon-text p {
    color: #434343;
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    padding: 0px 10px;
  }
  .chart {
    background-color: #f7f4f3;
    /* padding: 10px; */
    padding-block: 5px;
    /* margin: 20px; */
    margin: 20px 0px;
    border-radius: 10px;
  }
  .button-days {
    border-width: 0;
    background: transparent;
    border-right-width: 1px;
    font-size: 12px;
  }
  .button-day {
    border-width: 0;
    background: transparent;
    font-size: 12px;
  }
  .reports {
    font-size: 20px;
    font-weight: 700;
    color: #000;
  }
  .chart-image {
    width: 20px;
    height: 20px;
    background: #fce4db99;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chart-box {
    display: flex;
    align-items: center;
  }
  .chart-box h6 {
    color: #c4c4c4;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
  .chart-box p {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  .heading {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  .Campaign p {
    /* color: #ffffff; */
    color: black;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  .Campaign h6 {
    /* color: #ffffff; */
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }
  .status-live {
    /* color: #ffffff81; */
    color: #858585;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }
  .status-live span {
    /* color: #ffffff; */
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }
  .Campaign {
    display: flex;
    align-items: center;
  }
  .button-campaign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }

.left-kapil {
  display: flex;
  gap: 7px;
}
.left-right p:first-of-type {
  margin: 0;
  font-size: 12px;
  color: #959595;
}
.left-right p:last-of-type {
  color: black;
}
.left-right p:last-of-type {
  /* font-size: 25px; */
  font-size: 17px;
  font-weight: 700;
  display: flex;
  gap: 2px;
}
.left-right p:last-of-type span {
  font-size: 12px;
  color: #00cb14;
}
.spend2 h6 {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 11px;
  /* color: #ffffff; */
  color: black;
  margin: 0;
  font-weight: 500;
  opacity: 0.5;
}
/* .spend2 h6 span {
  opacity: 0.5;
} */
.spend2 p {
  font-size: 10px;
  /* color: #ffffff; */
  color: black;
  margin: 0;
  font-weight: 700;
  padding: 0px 10px;
}
.circle-type {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #62c;
}
.campaign-home-h6 {
  color: #959595 !important;
  max-width: 15ch !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.button-day {
  color: #62c;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
}
.chart-kapil-container {
  padding: 20px 12px;
  background-color: #f7f4f3;
  margin-inline: 20px;
  border-radius: 16px;
}
.chart-kapil-container .title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 20px;
  font-weight: 700;
  position: relative;
}
.chart-kapil-container .btns {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  width: 80px;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 4px;
  position: absolute;
  right: 0;
  z-index: 9;
  padding: 4px 3px;
}
.chart-kapil-container .btns span {
  cursor: pointer;
}
.impression-view-spend-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-inline: 30px;
}
.impression-view-spend-count .main-container {
  display: flex;
  gap: 5px;
  margin-top: 6px;
  flex-direction: row;
  align-items: center;
}
.impression-view-spend-count .image {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fce4db99;
}
.impression-view-spend-count .main-container .details p {
  font-size: 10px;
  color: #c4c4c4;
  margin-bottom: 0;
}
.impression-view-spend-count .main-container .details p:last-of-type {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.chart-kapil-container .title .btns {
  /* position: relative; */
  /* top: 25px; */
  /* max-width: 85px; */
  /* width: 100%; */
  width: fit-content;
}
.chart-kapil-container .title .btns .select-btn {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  background-color: white;
  padding-inline: 4px;
  gap: 8px;
  border-bottom: 0.4px solid #d9d9d9;
}
.chart-kapil-container .title .btns .select-btn-options {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #9f9f9f;
  cursor: pointer;
  text-align: left;
  padding: 0;
  padding-inline-start: 4px;
  border-bottom: 0.4px solid #d9d9d9;
  background-color: #fff;
}

.chart-kapil-container .title .btns .select-btn-options:nth-of-type(3) {
  border-bottom: 0px;
}
.boxshadow {
  border: 1px solid #e7eaee;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  /* background-color: #fff; */
}


@media (min-width:840px){
      .wallet-2{
          max-width: 305px;
      }
}

@media (min-width:768px) and (max-width: 992px) {

  .balance h6 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    margin: 5px 0px 0px 0px;
  }
  .balance p {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
  }
  .wallet-iconx{
    width: 22px;
    height: 22px;
  }

  .balance h6.company-name-wallet{
    font-size: 16px;
  }

  .balance .spent-till-text {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
  }
  .active-camp-text{
    font-size: 20px;
    position: absolut;
    top: 14px;
    left: 1px;
  }
  .spend h6{
    font-size: 12px;
  }

  

}

@media (min-width:992px) and (max-width: 1200px) {
  .balance h6 {
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    margin: 5px 0px 0px 0px;
  }
  .balance p {
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
  }

  .wallet-iconx{
    width: 15px;
    height: 15px;
  }

  .balance h6.company-name-wallet{
    font-size: 11px;
  }

  .balance .spent-till-text {
    color: #ffffff;
    font-size: 8px;
    font-weight: 500;
  }
  .active-camp-text{
    font-size: 11px;
   
  }
  .spend h6{
    font-size: 8px;
    
  }


}


