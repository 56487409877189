/* ============================================================================================== 
SED Innovations
https://sed.am
https://mkrtchyan.ga
================================================================================================= */


@keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

.stars, .twinkling {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
}

.stars {
  background:#000 url(https://ak.picdn.net/shutterstock/videos/22444189/thumb/1.jpg?ip=x480) repeat top center;
  z-index:0;
}

.twinkling{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
  z-index:1;

  -moz-animation:move-twink-back 200s linear infinite;
  -ms-animation:move-twink-back 200s linear infinite;
  -o-animation:move-twink-back 200s linear infinite;
  -webkit-animation:move-twink-back 200s linear infinite;
  animation:move-twink-back 200s linear infinite;
}


/* previewvideo3.css */

.video-player-wrapper {
  position: relative;
  padding-top: 45.25%; /* 16:9 Aspect Ratio */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.clickable-image {
  cursor: pointer;
}

.video-player-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: -11px;
  right: 2px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  outline: none;
  color: white; /* Adjust color to make the icon visible */
}

.close-button:hover {
  color: #ff0000; /* Optional: Change color on hover */
}


