/* .campignCompletedCard {
  border: 1px solid black;
} */
.campignCompletedCard {
  /* border: 1px solid black; */
  border-radius: 16.617px;
  background: #efebf4;
  font-family: "DM Sans", sans-serif;
  padding: 12px 17px;
}
.campignCompletedCard .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
}
.campignCompletedCard .header-left {
  display: flex;
  gap: 3px;
  align-items: center;
}
.campignCompletedCard .header .header-left-right {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  /* gap: 5px; */
}
.campignCompletedCard .header .header-left-right h3 {
  font-size: 16px;
  font-weight: 500;
}
.campignCompletedCard .header .header-right {
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  font-size: 12.463px;
  font-style: normal;
  line-height: normal;
}
.campignCompletedCard .header .header-right .row1 span {
  font-weight: 700;
}
.campignCompletedCard .header .header-right .row2 {
  display: flex;
  justify-content: space-between;
}
.campignCompletedCardDetails {
  display: flex;
  justify-content: space-between;
  margin-block: 20px;
}
.campignCompletedCardDetails .detail-left {
  display: flex;
  align-items: center;
  gap: 5px;
}
.campignCompletedCardDetails .detail-left-right {
  display: flex;
  flex-direction: column;
}
.campignCompletedCardDetails .detail-left-right p:first-of-type {
  margin-bottom: 0;
  font-size: 12px;
  color: #959595;
}
.campignCompletedCardDetails .detail-left-right p:last-of-type {
  /* font-size: 24.926px; */
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  display: flex;
  gap: 2px;
}
.campignCompletedCardDetails .detail-left-right p:last-of-type span {
  font-size: 12px;
  color: #00cb14;
}
