
.MuiFormHelperText-sizeMedium{
    text-align:end !important;
   
}

.send-otp-btn{
    bottom:4px;
    right:1px;
    width:82px;
     height:31px;
      border-radius:32px;
     padding:4px;
     background-color:rgba(102, 34, 204, 0.2);
    color:#6622CC;     
       font-size:14px;
        font-weight:500
    }
    .otp-verify-btn{
        bottom:4px;
        right:1px;
    }


    .image-preview-container {
        position: relative;
        display: inline-block;
        margin-top: 10px;
      }
      
      .image-preview {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
      
      .remove-button {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: red;
        color: white;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }