.button-navbar-container {
  padding-inline: 24px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  /* border: 1px solid red; */
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 0px -1px 27.4px 0px rgba(96, 83, 255, 0.15);
}
.button-navbar {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  /* border: 1px solid black; */
}
.button-navbar button {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.button-navbar-container button p {
  font-size: 10px;
  margin-bottom: 0;
  font-weight: 500;
  color: #828282;
}
.button-navbar .active {
  color: #63c;
}
.button-navbar-container a {
  height: 100%;
}
