.signup-container {
    position: relative;
    display: flex;
    margin: 0;
    flex: center;
    width: 100%;
    min-height: 100vh;
    overflow: hidden; 
    margin: 0;
    background-image: url('../asets/auth/bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
  }

  
  .signup-left-container{
    flex: 1;
  overflow: hidden;
  height: 100vh;
  max-width: calc(100vw - 525px); /* Adjust as per your layout */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  }
  /* .auth-bgimage {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  } */
  .auth-bgimage {
   margin: auto 0;
   max-width: 100%;

  height: auto;
   max-height: 100vh;
   object-fit: contain;
    z-index: 1;
  }
  .slickslider-card-p{
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
  .slick-testimonial-company{
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  .slick-testimonial-name{
    font-size: 16px;
    font-weight: 600;
    color: orange;
  }

  .signup-right-container{
    width: 525px;
    background-color: transparent;
    padding: 10px 15px 5px 0px;
  }

  
  .usersignup-card {
    width: 100%;
     margin-right: 20px;
    height: 96vh;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    z-index: 2;
    overflow-y: scroll;
    background-color: white; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    
  }
  .signup-mini-label{
    font-size: 1.1rem;
  }


.signup-email-label{
  color: rgb(25, 40, 57);
  font-family: Inter, "Inter Fallback Arial", Arial;
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  text-decoration-line: none;
  line-height: normal;
  letter-spacing: 0px;
  /* margin: 0px 0px 16px; */
  padding: 0px;
}
.signup-logo .google-heading-con{
 
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 1.3rem;
    font-weight: 600;
    font-style: normal;
    text-decoration-line: none;
    letter-spacing: 0px;
    margin-top: 10px;
    padding: 0px;
}
.signup-logo .signup-congration-p{
  color: rgb(118, 142, 167);
  font-family: Inter, "Inter Fallback Arial", Arial;
  font-size: 0.8rem;
  font-weight: 400;
  font-style: normal;
  text-decoration-line: none;
  line-height: 1.25rem;
  letter-spacing: 0px;
  margin-top: 5px;
  padding: 0px;
}

.slick-slider-container{
  /* position: absolute; */

  width: 100%;
  max-height: 140px;
   
  
}
.slick-img{
  width: 60px;
  max-width: 60px;
  
  border-radius: 999px;
}
.slider-container-card{
  width: 60%;
}


  .google-heading-signup{
    font-size: 1.3rem;
    font-weight: 700;
  }
  .google-heading-con{
    font-size: 1.5rem;
  }

  .signup-form-container{
    width: 81%;
    margin: 0 auto;
  }
  .signup-email-label{
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 1.3rem;
   
  }
  .google-heading-signup{
    font-size: 1.3rem;
    font-weight: 700;
  }
  .signup-logo .get-started{
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  .signup-already-account{
    font-size: 0.8rem;
    font-weight: 400;
    font-style: normal;
    text-decoration-line: none;
    line-height: 1.25rem;
    letter-spacing: 0px;
  }

  
  .brand-logo {
    width: 100px; /* Adjust as needed */
    height: auto;
  }
 
  /* usersignupmobile details */

.signup-card{
  width: 81%;
  margin: 0 auto;
}
  /* customize google login button */
  .google-login-button {
    width: 200px; /* Adjust the width as needed */
  }

  .signup-next-btncontainer{
    margin-top: 50px !important;
  }

  .terrm-of-use-container{
    width: 81%;
    margin: 0 auto;
  }
  .terrm-of-use-container p{
    color: rgb(118, 142, 167);
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 0.75rem;
    font-weight: 400;
    font-style: normal;
    text-decoration-line: none;
    line-height: 1.125rem;
    letter-spacing: 0px;
    text-align: center;
    margin: 0px;
    padding: 0px;
  }
  .cong-icon{
    max-width: 21px;
    height: auto;
    margin-left: 10px;
    
  }

  .left-card-height{
    /* height: 80vh; */
    /* margin-top: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .google-login-container {
    width: 100%;
    max-width: 400px;
    
    margin: 0 auto;
  } */

  /* #googleSignIn {
    width: 100%;
    max-width: 300px;
} */
  
  /* .google-login-container > :first-child {
    
     border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
  }  */

  /* .google-login-container div div iframe{
    width: 100% !important;
    
 }
 .S9gUrf-YoZ4jf{
  width: 100%;
 }
 .S9gUrf-YoZ4jf div div{
   padding: 10px 20px;
 }
 .S9gUrf-YoZ4jf div{
  width: 100%;
 }
 
 .haAclf{
  width: 100% !important;
 } */


 


  @media (max-width:768px){

    .signup-right-container{
      width: 100%;
      padding:0;
      background-color: white;
    }
   
    
  .usersignup-card {
    width: 100%;
     margin-right: 0px;
    height: 100%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    z-index: 0;
    overflow-y: scroll;
    
    box-shadow: none;
    border-radius: 0px;
    
  }

    .auth-bgimage {
       display: none;
    }

    .slider-container-card{
      width: 90%;
    }

    .signup-form-container{
      width: 90%;
      margin: 0 auto;
    }
    .signup-card{
      width: 98%;
      margin: 0 auto;
    }
     .terrm-of-use-container{
      width: 90%;
      margin: 0 auto;
    }
    .google-heading-signup{
      font-size: 1.1rem;
      font-weight: 700;
    }
    .signup-logo .google-heading-con{
      font-size: 1.1rem;
    }
    .google-heading-con{
      font-size: 1.4rem;
    }
    .terrm-of-use-container p{
      font-family: Inter, "Inter Fallback Arial", Arial;
      font-size: 0.7rem;
     
    }
    
    .signup-logo .signup-congration-p{
      font-family: Inter, "Inter Fallback Arial", Arial;
      font-size: 0.8rem;
      margin-top: 5px;
    }
    .signup-logo .get-started{
      font-family: Inter, "Inter Fallback Arial", Arial;
      font-size: 0.8rem;
      margin-top: 5px;
    }
    .signup-already-account{
      font-size: 0.8rem;
      font-weight: 400;
      font-style: normal;
      text-decoration-line: none;
      line-height: 1.25rem;
      letter-spacing: 0px;
    }
    .signup-mini-label{
      font-size: 1rem;
    }

   
    
  .signup-email-label{
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 1.1rem;
   
  }
  
  }

 
  

  @media (min-width: 768px) and (max-width: 992px) { 
    .signup-right-container{
      width: 357px;
      
      padding: 10px 5px 5px 0px;
    }

    .signup-left-container{
      max-width: calc(100vw - 357px); 
      display: flex;
      align-items: start;
      justify-content: center;
    
      }
      .slider-container-card{
        width: 93%;
      }
      .slick-slider-container{
        /* position: absolute;
        bottom: 20px; */
        width: 100%;
        max-height: 140px;
         
        
      }

      .google-heading{
        font-size: 1.3rem !important;
        font-weight: 700;
      }
     
      .terrm-of-use-container p{
        font-family: Inter, "Inter Fallback Arial", Arial;
        font-size: 0.75rem;
       
      }
      .signup-mini-label{
        font-size: 1rem;
      }
  
      
    
   
  .signup-email-label{
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 1.1rem;
   
  }
  .google-heading-signup{
    font-size: 1.1rem;
    font-weight: 700;
  }

  .signup-logo .google-heading-con{
    font-size: 1.1rem;
  }
  .signup-logo .get-started{
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  .signup-logo .signup-congration-p{
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  .signup-already-account{
    font-size: 0.8rem;
    font-weight: 400;
    font-style: normal;
    text-decoration-line: none;
    line-height: 1.25rem;
    letter-spacing: 0px;
  }
        

   }

   @media (min-width: 992px) and (max-width: 1200px) { 

    .slider-container-card{
      width: 70%;
    }
    .signup-left-container{
    max-width: calc(100vw - 470px); 
    display: flex;
    justify-content: center;
    align-items: center;
  
    }
    .signup-right-container{
      width: 470px;
      padding: 10px 5px 5px 0px;
    }

    .signup-form-container{
      width: 84%;
      margin: 0 auto;
    }
    .google-heading{
      font-size: 1.4rem !important;
      font-weight: 700;
    }
    .terrm-of-use-container p{
      font-family: Inter, "Inter Fallback Arial", Arial;
      font-size: 0.788rem;
     
    }
    .signup-logo .signup-congration-p{
      font-family: Inter, "Inter Fallback Arial", Arial;
      font-size: 0.78rem;
      margin-top: 5px;
    }
    .signup-already-account{
      font-size: 0.8rem;
      font-weight: 400;
      font-style: normal;
      text-decoration-line: none;
      line-height: 1.25rem;
      letter-spacing: 0px;
    }
    .signup-mini-label{
      font-size: 1.1rem;
    }

   
    .signup-email-label{
      font-family: Inter, "Inter Fallback Arial", Arial;
      font-size: 1.1rem;
     
    }
    .google-heading-signup{
      font-size: 1.1rem;
      font-weight: 700;
    }
    .signup-logo .google-heading-con{
      font-size: 1.1rem;
    }

   }



   /*  */

   .form-card-login{
    width: 70%;
}
.tab-container{
background-color: rgba(245, 245, 245, 1);
width: 320px;
border-radius: 999px;
}

.login-switch-tab-button{
   width: 154px;
   padding: 10px 0px;
    border-radius: 999px;
    border: none;
}
.shadow-btn{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.hover-btn:hover{
    border: 1px solid rgba(132, 132, 132, 1);
}

.img-top-logo{
    max-width: 295px;
    max-height: auto;
    height: auto;

}


/* /login user */
.form-card-login {
    background-color: #fff;
    border-radius: 8px;
    padding: 12px 12px 5px 12px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    
    overflow: hidden;
  
    margin-top: 24px;
    position: relative;
  }



@media (min-width: 768px) and (max-width: 992px) { 
    .form-card-login{
        width: 90%;
    }
    .img-top-logo{
        max-width: 100%;
        width: 80%;
        max-height:auto;
        height: auto;
    }
    .tab-container{
        background-color: rgba(245, 245, 245, 1);
        width: 100%;
        border-radius: 999px;
        }
      .form-card-login {
    width: 100%;
    box-shadow: none;
    padding: 8px;
    margin-top: 10px !important;
  }
}

@media (max-width:768px){
    .form-card-login{
        width: 98%;
    }
    .img-top-logo{
        max-width: 100%;
        width: 80%;
        height: auto;
        max-height:auto;
    }
    .tab-container{
        background-color: rgba(245, 245, 245, 1);
        width: 100%;
        border-radius: 999px;
        }
}



/* login with google */
