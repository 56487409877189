
.pages-container{
     padding: 14px;
}


    
.page-item{
    background-color:rgba(245, 245, 245, 1);
    opacity: 40%;
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    border: none;
    padding:14px 16px;


}
.page-item2{
    background-color:rgba(245, 245, 245, 1);
    opacity: 34%;
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    border: none;
    padding:14px 16px;
}
.page-item:hover{
   
    opacity: 100%;

     
}

.page-name{
   color:rgba(0, 0, 0, 1);
   font-weight: 600;
   font-size: 16px;
}

.page-event{
   color: #6622CC;
   font-size:14px;
   font-weight: 600;
}

.page-clicked{
    font-size: 14px;
}

.page-clicked span{
    font-size: 18px;
}

@media only screen and (max-width: 768px){
    .page-item{
        background-color:rgba(245, 245, 245, 1);
        opacity: 100%;
        filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
        transition: opacity 0.2s ease-in-out;
        width: 100%;
        border: none;
        padding:6px 8px;
    
    
    }
    .page-item2{
        background-color:rgba(245, 245, 245, 1);
        opacity: 100%;
        filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
        transition: opacity 0.2s ease-in-out;
        width: 100%;
        border: none;
        padding:6px 8px;
    }
    .page-name{
        color:rgba(0, 0, 0, 1);
        font-weight: 500;
        font-size: 13px;
     }
     .page-event{
        color: #6622CC;
        font-size:11px;
        font-weight: 500;
        min-width: 140px;
     }
     .page-clicked{
        font-size: 11px;
        font-weight: 600;
    }
    
    .page-clicked span{
        font-size: 14px;
        font-weight: 400;
    }
}