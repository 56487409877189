.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 21px;
  padding-block: 10px;
  border-bottom: 1px solid rgb(217, 217, 217, 0.5);
}
button {
  background-color: transparent;
  outline: none;
  border: none;
}
.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px 21px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  
}
.openedNavbar {
  width: 100vw;
  position: fixed;
  height: calc(100vh - 70px);
  top: 0;
  background-color: black;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(26.600000381469727px);
  z-index:10;
  /* overflow-y: scroll; */
}
.nav-links {
  margin-top: 20px;
  
}
.nav-links p {
  color: #fff;

  font-size: 16px;
  font-weight: 500;
  padding: 2px 10px;
  margin: 0;
  
}
.nav-links div {
  padding: 3px;
  cursor: pointer;
}

.dropbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 10px;
  margin: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 200px; /* Set a fixed width for the dropdown container */
}

.dropdown-content {
  overflow: hidden;
  max-height: 0;
  background-color: transparent;
  transition: max-height 0.3s ease-in-out;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-content a {
  color: white;
  padding: 4px 6px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: transparent;
}

.dropdown-content.show {
  max-height: 200px; /* Adjust this value based on the content's height */
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}
