.campignMgmtPage .previous {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-block: 20px 11px;
}

.campignMgmtPage .previous h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  font-family: "DM SANS", sans-serif;
}
.campignMgmtPage .filter-sort {
  height: 40px;
  width: 40px;
  background-color: rgba(230, 230, 230, 0.5);
  border-radius: 8px;
}
.campignMgmtPage .searchBox {
  height: 40px;
  min-width: 25ch;
  width: 40px;
  width: 100%;
  border-radius: 8px;
  background: rgba(230, 230, 230, 0.5);
  padding-inline: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.campignMgmtPage .searchBox input {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: #949494;
}
