.leadpage .previous {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-block: 20px 11px;
}

.leadpage .previous h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  font-family: "DM SANS", sans-serif;
}
.leadpage .filter-sort {
  height: 40px;
  width: 40px;
  background-color: rgba(230, 230, 230, 0.5);
  border-radius: 8px;
}
.leadpage .searchBox {
  height: 40px;
  min-width: 25ch;
  width: 40px;
  border-radius: 8px;
  background: rgba(230, 230, 230, 0.5);
  padding-inline: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.leadpage .searchBox input {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: #949494;
}
.tabBtns {
  display: flex;
  gap: 5px;
  margin-block: 18px 25px;
  padding-bottom: 4px;
}
.tabBtn {
  color: #828b96;
  text-align: center;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  padding-bottom: 4px;
}
.tabBtnSelected {
  color: #62c;
  border-bottom: 1px solid #62c;
  /* padding-bottom: 4px; */
  font-weight: 500;
}
.userDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: #f7f4f3;
  padding-inline: 13px;
  border-radius: 12px;
}
.userDetails-skelton{
  height: 64px;
  background-color: #f7f4f3;
  padding-inline: 13px;
  border-radius: 12px;
}
.userDetails .left {
  display: flex;
  align-items: center;
}
.userDetails .left .user-details h2 {
  font-size: 16px;
  font-style: normal;
  color: #2c2c2c;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.userDetails .left .user-details p {
  color: #a5a5a5;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.userDetails button {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: #6622cc;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: white;
}
.pagination-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-block: 10px;
}

/* right card */
.dropdown-btn .btn{
    background-color: white !important;
    color: black !important;
}

.form-dropdown-menu{
  background-color: white !important;
   /* width: 120px !important; */
   /* inset: 2px auto auto 4px !important; */
}
.form-dropdown-menu li{
 
  color: black !important;
}
.table-comment{
  margin-top: 20px;
  border-radius: 8px;
}
.table-comment tr.head{
      border-radius: 4px 0px 0px 4px;
}
.table-comment tr th{
     border: 1px solid rgba(181, 181, 181, 1);
     padding: 20px;
     color: white;
}
.table-comment tr td{
  border: 1px solid rgba(181, 181, 181, 1);
  padding: 20px;

}
 .form-quetion::first-letter{
  text-transform: uppercase;

}
.comment-box {
  border: 1px solid #ccc; /* Default border color */
 
}

.comment-box:focus {
  border-color: #007bff;
}
.comment-box:focus {
  border: #007bff;
}
.table-comment-container{
  height: 0;
  overflow: hidden;
}

.table-comment-container.show-comment-card {
  animation: fadeIn 0.5s forwards; /* Use fadeIn animation when the card is open */
}
@keyframes fadeIn {
  from {
    height: 0; /* Start with height 0 */
  }
  to {
    height: 200px; /* Expand to the desired height */
  }
}