.supportSuccessContainer section {
  padding-inline: 28px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
}
.supportSuccessContainer section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 287px;
  margin: 0 auto;
}
.supportSuccessContainer section .image {
  padding-inline: 40px;
}
.supportSuccessContainer section p {
  font-size: 12px;
  text-align: center;
  margin-top: 21px;
  color: #6c6c6c;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.supportSuccessContainer section p > span {
  color: #f07a4c;
}
.supportSuccessContainer section a {
  width: 100%;
}
.supportSuccessContainer section button.dashboardBtn {
  width: 100%;
  border-radius: 8px;
  margin-top: 80px;
  background-color: #6622cc;
  color: white;
  height: 54px;
}
