/* facebook pages */

.addpages-name{
    font-size: 14px;
    font-weight: 500;
    color:rgba(0, 0, 0, 1);
    line-height: 20px;
    font-family: "Inter", sans-serif !important;
}
.addpages-p{
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   color: rgba(51, 65, 85, 1);
   font-family: "Inter", sans-serif !important;
}
.addpage-card-bg{
    background-color: rgba(255, 255, 255, 1);
    font-family: "Inter", sans-serif !important;
   
}


.addpages-main-h{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(15, 23, 42, 1);
    font-family: "Inter", sans-serif !important;
}

.addpages-middle-text{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(15, 23, 42, 1);;
    font-family: "Inter", sans-serif !important;
}

/* account details */
.ad-form-container{
    width: 55%;
}
.ad-main-h{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(15, 23, 42, 1);
}
.ad-label{
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(9, 9, 11, 1);
}
.ad-bg-card{
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(203, 213, 225, 1);
}

.ad-formsubmit-btn{
    font-size: 14;
    /* line-height: 24; */
    width: 96px;
    height: 40px;
    font-weight: 500;
    background-color: rgba(108, 92, 231, 1);
    border: none;
    border-radius: 6px;
    color: white;
   /* padding:8px, 16px, 8px, 16px; */
}


/* shadcn */
.border-input{
   border: 1px solid rgba(228, 228, 231, 1); 
}

/* for phone */
.custom-phone-input .PhoneInputInput {
    height: 40px; /* Adjust height as needed */
    padding: 8px 12px; /* Adjust padding as needed */
    border-radius: 4px; /* Adjust border-radius as needed */
    border: 1px solid #ccc; /* Adjust border color as needed */
    font-size: 16px; /* Adjust font size as needed */
  }
  
  .custom-phone-input .PhoneInputInput:focus {
    outline: none;
    border-color: #4a90e2; /* Adjust focus border color as needed */
  }

  .custom-phone-input .PhoneInputCountry {
    display: flex;
    margin-right: 0px;
    align-items: center;
    border: 1px solid rgba(203, 213, 225, 1); /* Adjust border color as needed */
    border-right: none; /* Remove right border to avoid overlap */
    border-radius: 4px 0 0 4px; /* Remove right border-radius */
    padding: 0 8px; /* Adjust padding as needed */
  }
  
  .custom-phone-input .PhoneInputCountrySelect {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
  }
  
  .custom-phone-input .PhoneInputCountryIcon {
    width: 20px; /* Adjust width as needed */
    height: 20px; /* Adjust height as needed */
  }
  
  .custom-phone-input .PhoneInputInput {
    height: 40px; /* Adjust height as needed */
    padding: 8px 12px; /* Adjust padding as needed */
    border: 1px solid rgba(203, 213, 225, 1); /* Adjust border color as needed */
    border-radius: 0 4px 4px 0; /* Remove left border-radius */
    font-size: 16px; /* Adjust font size as needed */
    /* border-left: none; Remove left border to avoid overlap */
  }
  
  .custom-phone-input .PhoneInputInput:focus {
    outline: none;
    border-color: rgba(203, 213, 225, 1); /* Adjust focus border color as needed */
  }
  .custom-phone-input[disabled] .PhoneInputInput {
    color: transparent;
    text-shadow: 0 0 0 black;
}


/* notification settingd */
.campaign-alrt-text{
    font-size: 14px;
    font-weight: 500;
    color: rgba(9, 9, 11, 1);
}
.notification-btn{
    font-size: 24px;
    font-weight: 500;
}
.notificationemail-p{
    font-size: 14px;
    font-weight: 400;
    color: rgba(9, 9, 11, 0.5);
    line-height: 20px;
}

.ad-main-h{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
}

.shadow-accordion{
 
    box-shadow: 0px 1.33px 2.66px 0px rgba(0, 0, 0, 0.12);
  
}


/* fb pages */
@keyframes refreshpage {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.refreshpage {
  animation: refreshpage 1s linear infinite;
}

@keyframes smooth-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg); /* Continuous rotation */
  }
}

.animate-smooth-spin {
  animation: smooth-spin 1s linear infinite; /* Adjust duration and timing as needed */
}


/* for mobile */
@media(max-width:768px){
    .addpages-name{
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        
    }
    .addpages-p{
       font-size: 12px;
       font-weight: 400;
       line-height: normal;
      
    }
 
    
    
    .addpages-main-h{
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(15, 23, 42, 1);
        font-family: "Inter", sans-serif !important;
    }
    
    .addpages-middle-text{
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
      
    }
    .ad-main-h{
        font-size: 18px;
        font-weight: 500;
        line-height: 36px;
    }
    
}
