.add-overviewPage {
  overflow-x: hidden;
 
}
.add-overviewPage .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
}
.add-overviewPage .header-left {
  display: flex;
  gap: 3px;
  align-items: center;
}
.add-overviewPage .header .header-left-right {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  /* gap: 5px; */
}
.add-overviewPage .header .header-left-right h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.add-overviewPage .header .header-right {
  display: flex;
  flex-direction: column;
  font-family: DM Sans, sans-serif;
  font-size: 12.463px;
  font-style: normal;
  line-height: normal;
}
.add-overviewPage .header .header-right .row1 span {
  font-weight: 700;
}
.add-overviewPage .header .header-right .row2 {
  display: flex;
  justify-content: space-between;
}
.status-live-or-not span {
  opacity: 50%;
  font-weight: 500;
}
.add-overviewPage .campign-info-btn {
  background-color: #fce4db99;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-overviewPage .campign-info-heading {
  color: #c4c4c4;
  font-size: 10px;
}
.add-overviewPage .campaign-impression-details span:last-of-type {
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}

.MuiSlider-valueLabelOpen {
  background-color: #62c !important;
  width: 34px;
  height: 27px;
  font-size: 12px !important;
}
.MuiSlider-valueLabelLabel {
  font-size: 12px;
}
.css-yafthl-MuiSlider-markLabel,
.css-1eoe787-MuiSlider-markLabel {
  top: 30px !important;
  font-size: 12px !important;
  color: #b6b6b6 !important;
}
/* .css-yafthl-MuiSlider-markLabel {
  right: -100px;
} */
/* .css-1eoe787-MuiSlider-markLabel {
  left: 10px !important;
} */
.pause-ok-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.pause-btn {
  border: 1px solid #62c;
  border-radius: 4px;
  padding: 4px 8px;
}
.pause-text {
  color: #fff !important;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ok-btn {
  background-color: #62c;
}

.promoted-on-and-ad-type span {
  color: #000;
  font-family: DM Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
 .supportBtns button {
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 5px;
  align-items: center;
  min-width: 150px;

  height: 48px;
  justify-content: center;
  border-radius: 5px;
}
 .supportBtns button.backBtn {
  color: #62c;
  border: 1px solid #62c;
}
 .supportBtns button.incBudgetBtn {
  background-color: #62c;
  color: white;
}
.supportBtns button.supportBtn {
  color: #f07a4c;
  border: 1px solid #f07a4c;
}
.add-overviewPage .keyHeighlightsChipsInput .MuiChip-label {
  font-size: 12px;
}

.add-overviewPage .keyHeighlightsChipsInput .MuiChip-label .MuiChip-deleteIcon {
  font-size: 12px;
}
.inputImageContainer {
  height: 50px;
  width: 80px;
  object-fit: cover;
}
.inputImageContainer img {
  /* height: 50px; */
  /* width: 80px; */
  height: 100%;
  width: 100%;
}

.add-overviewPage
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root:hover:not(
    .Mui-disabled
  ) {
  border-bottom: 1px solid grey;
}

.tooltip {
  position: relative;
  float: right;
}
.tooltip > .tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: rgb(23, 44, 66);
  font-weight: bold;
  font-size: 13px;
}
.popOver + .tooltip > .tooltip-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #eebf3f;
}

.add-overviewPage section {
  margin: 140px auto;
  height: 1000px;
}
.progress {
  border-radius: 0;
  overflow: visible;
}
.progress-bar {
  background: rgb(23, 44, 60);
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
}
.ad-overview-chart .btns {
  position: absolute;
  top: -3px;
  right:4px;
  z-index: 8;

  max-width: 85px;
  background-color:#fff;
  width: 100%;
  border: 1px solid #e7eaee;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.ad-overview-chart .btns .select-btn {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  gap: 8px;
  width: 100%;
  padding-inline: 4px;
  border-bottom: 0.4px solid #d9d9d9;
}
.ad-overview-chart .btns .select-btn-options {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding-inline: 4px;
  line-height: 21px;
  color: #9f9f9f;
  cursor: pointer;
  width:100%;
  text-align: left;
  padding: 0;
  padding-inline: 4px;
  border-bottom: 0.4px solid #d9d9d9;
}

.ad-overview-chart .btns .select-btn-options:nth-of-type(3) {
  border-bottom: 0px;
}

.skeleton-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.adpreview-heading{
    padding-left: 2.6rem;
}


.previewcard-shadow{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.overview-cc-text{
  font-size: 14px;
  font-weight: 500;
  
}

@media (max-width:780px){
  .adpreview-heading{
    padding-left: 0px;

    


}

.overview-cc-text{
  font-size: 12px;
  font-weight: 500;
  
}
}