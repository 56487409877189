
.threestep-heading-container{
    top: 50px;
}
.threestep-position{
    top: 120px;
}

.threestep-gradient2 {
    background: radial-gradient(circle at 69% 100%, rgba(16, 16, 16, 1) 0%, #a44810 0%, rgba(0, 0, 0, 1) 30%);

}
.threestep-gradient{
    background: radial-gradient(circle at 25% 100%, rgba(16, 16, 16, 1) 0%, #a44810 0%, rgba(0, 0, 0, 1) 30%);
}

.threestep-height{
   /* min-height: 364px; */
    }
.threestep-img{
    height: 364px;
    position: relative;
    /* background: radial-gradient(circle at 48% 100%, rgba(16, 16, 16, 1) 0%, rgba(102, 34, 204, 1) 0%, rgba(0, 0, 0, 1) 53%); */
}
.threestep-main-img{
    max-width: 100%;
    object-fit: cover;
    height: 400px;
    /* height: auto; */
 }
 .threestep-body-width{
    max-width: 64%;
 }
 .threestep-content-box-width-2{
    min-width: 50%;
    max-width: 55%;
 }


.threestep-content-box{
    position: relative;
}

.threestep-content{
    min-height: 160px;
   
    position: relative;
}
.threestep-subh{
    position: relative;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
   
    color: white;
}

.threestep-subh::before{
    content: "";
    width: 4px;
    height: 23px;
    /* margin-right: 8px; */
    background-color: #dc5300;
    display: inline-block;
    position: absolute;
    left: -18px;
    top: 4px;
    transform: rotate(15deg);
}

.threestep-h{

    font-size: 4.6rem;
    font-weight: 600;
    font-family: "Instrument Sans", sans-serif;
   line-height: normal;
   padding: 0 !important;
    color: white;
}
.threestep-body{
    font-size: 15px;
    font-weight: 400;
    position: relative;
    font-family: "Instrument Sans", sans-serif;
   
    color: white;
}

.threestep-body::before{
   content: "";
   width: 74px;
    height: 1px;
    background-color: white;
    position: absolute;
    bottom:-10px;   
    transform: rotate(180deg);

}



@media (max-width:764px){
    .threestep-position{
        top: 80px;
    }
    .threestep-heading-container{
        top: 38px;
    }
    .threestep-height{
        min-height: 300px;
         }
    .threestep-img{
        height: auto;
        position: relative;
        /* background: radial-gradient(circle at 48% 100%, rgba(16, 16, 16, 1) 0%, rgba(102, 34, 204, 1) 0%, rgba(0, 0, 0, 1) 53%); */
    }
    .threestep-main-img{
       width: auto;
       height: auto;
    }
    .threestep-content {
        min-height: auto;
        position: relative;
        max-width: 265px;
    }

    .threestep-subh{

        font-size: 1.3rem;
      
       
        color: white;
    }
    .threestep-subh::before{
        content: "";
        width: 4px;
        height: 23px;

        /* margin-right: 8px; */
        background-color: #dc5300;
        display: inline-block;
        position: absolute;
        left: 38px;
        top: 4px;
        transform: rotate(15deg);
    }
    
    .threestep-h{
    
        font-size: 2.2rem;
        font-weight: 600;
        font-family: "Instrument Sans", sans-serif;
       
        color: white;
    }
    .threestep-body{
        font-size: 0.9rem;
        font-weight: 400;
        font-family: "Instrument Sans", sans-serif;
       
        color: white;
    }
    .threestep-body-width{
        max-width: 70%;
    }
    .threestep-body::before{
        content: "";
        width: 74px;
         height: 1px;
         background-color: white;
         position: absolute;
         bottom:-10px; 
         left: 30%;  
         transform: rotate(180deg);
     
     }
     .threestep-gradient {
        background: radial-gradient(circle at 36% 100%, rgba(16, 16, 16, 1) 0%, #a44810 0%, rgba(0, 0, 0, 1) 30%);
    
    }
    .threestep-gradient2 {
        background: radial-gradient(circle at 39% 100%, rgba(16, 16, 16, 1) 0%, #a44810 0%, rgba(0, 0, 0, 1) 30%);
    }
}