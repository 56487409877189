.table-hover-web th td{
  transition: background-color 0.3s ease-in-out;
}

.table-hover-web:hover{
    background-color: #b7b5bb;
  
}

.table-hover-web:hover td{
    color: white ;
}



.table-td-padding{
  padding: 14px 8px !important;
}
.tablewidth{
  width: 240px;
}
.serailnum-width{
  width: 65px;
}

.table-web tbody{
  /* height: 300px !important; */
  overflow-y: scroll;
}